import React from 'react';
import afrilab from '../assets/imgs/afrilab.png';
import alpha_blue from '../assets/imgs/alphablue.png';
import blue from '../assets/imgs/blue-algorithm.png';
import kcca from '../assets/imgs/kcca-logo.png';
import kusu_logo from '../assets/imgs/kusu_logo.png';
import netaccess from '../assets/imgs/netaccess.jpeg';
import treasurefield from '../assets/imgs/treasurefield.jpeg';
import plasmida from '../assets/imgs/plasmida.jpeg';
import platech from '../assets/imgs/platech.jpeg';
import greg from '../assets/imgs/greg.jpeg';
import german from '../assets/imgs/german.jpeg';
import krp from '../assets/imgs/krp.jpeg';
import integrated from '../assets/imgs/integrated.jpeg';
import tarmac from '../assets/imgs/tarmac.jpeg';
import magxi from '../assets/imgs/magxi.jpeg';
import nasco from '../assets/imgs/nasco.jpeg';

const Partners = ({ second }) => {
	return (
		<div
			className={`${
				second ? 'bg-white' : 'bg-[#F8F6F5]'
			} md:h-[360px] pt-[64px] px-[30px]`}
		>
			<h1 className='font-[600] text-[20px] md:text-[30px] text-center mb-[30px] md:mb-[37px]'>
				{/* {new Date().getFullYear()} ADCA PARTNERS */}
				2023 ADCA PARTNERS
			</h1>
			<marquee Scrolldelay='60'>
				<div className='flex items-center justify-center md:h-[178px] gap-[12px]'>
					<img
						src={kcca}
						alt='KCCA logo'
						className='mt-[20px] md:mt-[0px] w-4/6 mx-auto md:mx-0'
					/>
					<img
						src={afrilab}
						alt='afrilab logo'
						className='mt-[20px] md:mt-[0px] w-4/6 mx-auto md:mx-0'
					/>
					<img
						src={alpha_blue}
						alt='alpha_blue logo'
						className='mt-[30px] md:mt-[0px] w-4/6 mx-auto md:mx-0'
					/>
					<img
						src={blue}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-0'
					/>
					<img
						src={kusu_logo}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-0 md:w-[80px] h-[50px]'
					/>
					<img
						src={netaccess}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-[40px] md:w-[180px] h-[80px]'
					/>
					<img
						src={treasurefield}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={plasmida}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={platech}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={greg}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={german}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={krp}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={integrated}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={tarmac}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={magxi}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
					<img
						src={nasco}
						alt='blue algorithm logo'
						className='mt-[30px] w-4/6 mx-auto md:mx-3 md:w-[80px] h-[80px]'
					/>
				</div>
			</marquee>
		</div>
	);
};

export default Partners;
