import React, { useEffect, useState } from 'react';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import frame from '../../assets/imgs/award.png';
import Animation from '../../Components/Animation';
import { Link } from 'react-router-dom';
import { url } from '../../url';
const Nominees = () => {
	const [info, setInfo] = useState('');
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const fetchData = async () => {
		const response = await fetch(`${url}/vote/candidates`);
		const data = await response.json();
		setInfo(data);
	};
	//   fetchData();

	useEffect(() => {
		fetchData();
		return () => {
			fetchData();
		};
	}, []);
	if (info) console.log(info.map((i) => console.log(i)));

	return (
		<div>
			<Nav />
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[25px] md:text-[40px]'>
						2022 Approved ADCA Nominees
					</h1>
					<div className='w-[64px] h-[5px] mt-[8px] bg-primary'></div>
					{/* <div className="block lg:flex"> */}
					<article className=''>
						<p
							className='font-[400] text-[18px] md:text-[24px] text-left mt-[32px]'
							// style={{ lineHeight: "32px" }}
						>
							The ADCA nominations, which attracted applications
							from 5 (five) African countries have unveiled the
							shortlisted candidates for voting, after a thorough
							screening process. The African-based award is set to
							honour people and organisations who are creating
							disruptive technologies. It is estimated that the
							voting process would take a minimum of 15 days. The
							winners of each category would be announced on the
							ADCA 2022 Gala Night at Crispan Hotel and Suites,
							Jos, Plateau State, Nigeria. To register either
							virtually or physically, for this event,{' '}
							<Link to='/reservation' className='text-primary'>
								click here
							</Link>
						</p>
						<p
							className='font-[600] text-[18px] md:text-[24px] text-center my-[32px]'
							style={{ lineHeight: '32px' }}
						>
							Below are the shortlisted candidates for each of the
							categories:
						</p>
						<div class='flex flex-col'>
							<div class='overflow-x-auto sm:-mx-6 lg:-mx-8'>
								<div class='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
									<div class='overflow-x-auto'>
										<table className='min-w-full'>
											<thead className='border-b text-center'>
												<tr>
													<th className='text-lg font-bold text-gray-900 px-6 py-4 text-left md:text-center'>
														Nominee
													</th>
													<th className='text-lg font-bold text-gray-900 px-6 py-4 text-left md:text-center'>
														Award Name
													</th>
													{/* <th className="text-lg font-bold text-gray-900 px-6 py-4 text-left md:text-center">
                            Type of Award
                          </th> */}
												</tr>
											</thead>
											<tbody>
												{info &&
													info.map((user) => (
														// <li className="min-w-[100%] mx-auto md:mx-0 md:w-[45%] p-[16px]">
														//       {user.nomName}

														// </li>
														<tr className='border-b border-blue-200'>
															<td className='px-6 py-4 whitespace-nowrap text-sm  font-bold text-gray-900 capitalize '>
																{user.nomName}
															</td>
															<td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize '>
																{
																	user.subcategory
																}
															</td>
															{/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {user.nomineeType}
                              </td> */}
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</article>
					{/* <span className="">
              <img src={frame} alt="about" className="hidden lg:block" />
            </span> */}
					{/* </div> */}
				</div>
			</Animation>
			<Footer />
		</div>
	);
};

export default Nominees;
