import React from 'react';
import { Link } from 'react-router-dom';
import About from '../../Components/About';
import Animation from '../../Components/Animation';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import frame from '../../assets/imgs/award.png';

const Entry = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Nav />
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px]'>
						Who can Enter
					</h1>
					<div className='w-[96px] h-[5px] mt-[8px] bg-primary'></div>
					<div className='block md:flex'>
						<article className='w-5/6 mx-auto md:mx-0 md:w-[817px] md:mr-[66px] mb-[30px] md:mb-0'>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-left mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								The Africa Digital Conference & Awards is open
								to all key stakeholders in the tech industry.
								This includes tech organisations, tech experts,
								tech innovators, entrepreneurs, individuals, as
								well as policymakers among others.
							</p>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-left mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								In the case of organisations, it is important to
								note that ADCA expects that all organisations
								nominated in any given category should be duly
								registered organisations. In the case of
								individuals, we only accept nominees who have
								proven track records ad are credible. Group and
								teams within an organisation may submit entries
								in any category that represents their interest.
							</p>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-left mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								To nominate an organisation, individual or team,{' '}
								<Link
									to='/nomination-portal'
									className='text-primary'
								>
									Nomination Portal
								</Link>{' '}
								. For further information or inquiries, call{' '}
								<em>+2347018245477 </em> or send a mail to{' '}
								<em>
									africandigitalconferenceawards@gmail.com
								</em>{' '}
								or <em>info@adcaward.com</em>
							</p>
						</article>
						<span className=''>
							<img src={frame} alt='about' />
						</span>
					</div>
				</div>
			</Animation>
			<Footer />
		</div>
	);
};

export default Entry;
