import React, { useState, useEffect } from 'react';
import About from '../../Components/About';
import Animation from '../../Components/Animation';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Partners from '../../Components/Partners';
import { url } from '../../url';

const Categories = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [fetchCategory, setFetchCategory] = useState([]);
	const fetchCategoriesfunc = async () => {
		const response = await fetch(`${url}categories`);
		const data = await response.json();
		setFetchCategory(data);
		console.log(data);
	};

	useEffect(() => {
		fetchCategoriesfunc();
		return () => {
			fetchCategoriesfunc();
		};
	}, []);
	console.log(fetchCategory);

	return (
		<div>
			<Nav />
			<Animation>
				<About
					title='AWARD CATEGORIES'
					text1='The African Digital Conference and Awards especially recognize, celebrate and reward Tech experts, Innovators, Entrepreneurs, Academicians, Policymakers, Organizations, and Key Stakeholders in the tech industry across Africa. '
					text2='The ceremony also provides an opportunity for distinguished Academicians, Policymakers, and Tech Experts across the globe to present technical papers that align with the theme of the conference. The aim of such presentations is to inspire, educate or, inform all key stakeholders in the tech industry.'
					text3='ADCA also provides a platform for Tech Innovators, Entrepreneurs, and Organizations to showcase their ideas, prototypes, or products to varying audiences from different backgrounds. The event also provides an avenue for digital tech awareness and the need for African countries to leverage technology for sustainable growth and development of their economies.'
					text4='entrepreneurs, innovators, and various key stakeholders in the tech industry to industry trends.The African Digital Conference and Awards is an initiative of the Alpha Blue Foundation which since its inception is committed to the development of a sustainable African nation through technology. Thus, the ADCA is geared to expose young'
					text5='Some key features of the African Digital Conference and Awards are: Keynote Presentations,  Workshop & Fire Charts, Networking, and Awards & Recognition,'
				/>
				{/* <div className="md:flex flex-wrap mx-[20px] md:mx-[60px]">
          {fetchCategory &&
            fetchCategory.map((category) => (
              <div className="md:w-2/3 mx-auto">
                {category.description && (
                  <h4 className="mb-[8px] mt-[32px] font-[600] text-[18px] leading-6 flex">
                    {category.category}
                  </h4>
                )}
                <p className="mb-[8px] mt-[12px] font-[400] text-[16px] leading-6 flex">
                  {category.description}
                </p>
              </div>
            ))}
        </div> */}
			</Animation>
			<Partners />
			<Footer />
		</div>
	);
};

export default Categories;
