import React from 'react';
// import About from '../../Components/About';
import Animation from '../../Components/common/Animation';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Partners from '../../Components/Partners';
import Schedule from '../../Components/Schedule';
import frame from '../../assets/imgs/award.png';
import CustomCarousel from '../../Components/common/CustomCarousel';
import cong2 from '../../assets/imgs/award/two.JPG';
import speaker from '../../assets/imgs/award/aw.JPG';
import firechat from '../../assets/imgs/award/new/cong9.jpg';
import workshop from '../../assets/imgs/award/new/cong5.jpg';
import networking from '../../assets/imgs/award/new/cong7.jpg';
import award from '../../assets/imgs/award/new/cong3.jpg';

const About = ({ title, text1, enter }) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='overflow-hidden'>
			<Nav />
			<div className=''>
				<CustomCarousel />
			</div>
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<h1 className='font-[600] text-[20px] md:text-[30px] text-center'>
						ABOUT AFRICAN DIGITAL CONFERENCE AND AWARDS(ADCA)
					</h1>
					<div className='mx-auto w-[64px] h-[2px] mt-[3px] bg-primary'></div>
					<div className='flex flex-col gap-y-9 mt-4'>
						<Animation
							style='fade-left'
							placement='center-center'
							className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
						>
							<div className='flex flex-col md:flex-row gap-5 items-center'>
								<article className='flex-1'>
									<p
										className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'
										style={{ lineHeight: '32px' }}
									>
										Welcome to the Africa Digital Conference
										and Award, where we celebrate the
										vibrant and rapidly evolving digital
										landscape across the African continent.
										This groundbreaking event brings
										together industry leaders, innovators,
										and visionaries to explore the latest
										trends, share insights, and recognize
										outstanding achievements in the digital
										realm. Get ready for a dynamic program
										that includes captivating keynotes,
										engaging workshops, enlightening
										fireside chats, invaluable networking
										opportunities, prestigious awards and
										recognition ceremonies, and an exciting
										exhibition and showcasing of
										cutting-edge technologies.
									</p>
								</article>
								<div className='flex-1'>
									<img src={cong2} alt='about' />
								</div>
							</div>
						</Animation>
						<Animation
							style='fade-right'
							placement='center-center'
							className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
						>
							<div className='flex flex-col-reverse md:flex-row-reverse justify-between gap-5 items-center'>
								<article className='flex-1'>
									<h1 className='font-[600] text-[18px] md:text-[25px] my-5'>
										Keynote Sessions:
									</h1>
									<p
										className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'
										style={{ lineHeight: '32px' }}
									>
										Our esteemed keynote speakers, renowned
										experts in their fields, will inspire
										and enlighten attendees with their
										visionary perspectives on the digital
										landscape in Africa. They will share
										invaluable insights, trends, and
										strategies that are shaping the future
										of various industries across the
										continent. Be prepared to gain valuable
										knowledge and inspiration from these
										thought leaders who are at the forefront
										of digital transformation.
									</p>
								</article>
								<div className='flex-1'>
									<img src={speaker} alt='about' />
								</div>
							</div>
						</Animation>
						<Animation
							style='fade-left'
							placement='center-center'
							className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
						>
							<div className='flex flex-col md:flex-row justify-between gap-5 items-center'>
								<article className='flex-1'>
									<h1 className='font-[600] text-[18px] md:text-[25px] my-5'>
										Workshops:
									</h1>
									<p
										className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'
										style={{ lineHeight: '32px' }}
									>
										Our interactive workshops provide a
										unique opportunity for attendees to
										delve deeper into specific digital
										topics and acquire practical skills. Led
										by industry professionals, these
										sessions offer hands-on experiences,
										best practices, and case studies that
										will empower participants to harness the
										full potential of digital technologies.
										Whether you are interested in AI,
										blockchain, cybersecurity, e-commerce,
										or digital marketing, our workshops will
										provide you with actionable insights to
										enhance your digital endeavors.
									</p>
								</article>
								<div className='flex-1'>
									<img src={workshop} alt='about' />
								</div>
							</div>
						</Animation>
						<Animation
							style='fade-right'
							placement='center-center'
							className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
						>
							<div className='flex flex-col-reverse md:flex-row-reverse justify-between gap-5 items-center'>
								<article className='flex-1'>
									<h1 className='font-[600] text-[18px] md:text-[25px] my-5'>
										Fireside Chats:
									</h1>
									<p className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'>
										Engage in captivating fireside chats
										with prominent figures in the digital
										arena. These intimate and informal
										conversations will offer an inside look
										into the journeys, challenges, and
										triumphs of industry leaders. Gain
										valuable wisdom and learn from their
										experiences as they share their personal
										insights and strategies for success in
										the digital realm.
									</p>
								</article>
								<div className='flex-1'>
									<img src={firechat} alt='about' />
								</div>
							</div>
						</Animation>
						<Animation
							style='fade-left'
							placement='center-center'
							className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
						>
							<div className='flex flex-col md:flex-row justify-between gap-5 items-center'>
								<article className='flex-1'>
									<h1 className='font-[600] text-[18px] md:text-[25px] my-5'>
										Networking Opportunities:
									</h1>
									<p className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'>
										Forge meaningful connections with fellow
										professionals, industry experts, and
										potential collaborators during our
										networking sessions. Connect with
										like-minded individuals, exchange ideas,
										and explore potential partnerships that
										can drive innovation and growth in the
										digital space. Take advantage of this
										unique platform to expand your network
										and establish valuable relationships
										that can propel your digital initiatives
										forward.
									</p>
								</article>
								<div className='flex-1'>
									<img src={networking} alt='about' />
								</div>
							</div>
						</Animation>
						<Animation
							style='fade-right'
							placement='center-center'
							className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
						>
							<div className='flex flex-col-reverse md:flex-row-reverse justify-between gap-5 items-center'>
								<article className='flex-1'>
									<h1 className='font-[600] text-[18px] md:text-[25px] my-5'>
										Awards and Recognition:
									</h1>
									<p className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'>
										We believe in celebrating exceptional
										achievements in the digital domain. The
										Africa Digital Awards will recognize
										outstanding individuals, organizations,
										and initiatives that have made
										significant contributions to the
										advancement of digital technologies in
										Africa. From groundbreaking startups to
										established industry leaders, we will
										honor those who have demonstrated
										innovation, excellence, and social
										impact across various categories. Join
										us as we shine a spotlight on the best
										and brightest in Africa's digital
										landscape.
									</p>
								</article>
								<div className='flex-1'>
									<img src={award} alt='about' />
								</div>
							</div>
						</Animation>
						<Animation
							style='fade-left'
							placement='center-center'
							className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
						>
							<div className='flex flex-col md:flex-row justify-between gap-5 items-center'>
								<article className='flex-1'>
									<h1 className='font-[600] text-[18px] md:text-[25px] my-5'>
										Exhibition and Showcasing:
									</h1>
									<p className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'>
										Immerse yourself in the latest
										advancements and cutting-edge
										technologies at our exhibition. Explore
										a diverse range of digital products,
										services, and solutions showcased by
										industry-leading companies, startups,
										and innovators. Witness firsthand how
										these technologies are revolutionizing
										sectors such as healthcare, agriculture,
										finance, education, and more. Interact
										with exhibitors, participate in live
										demos, and gain inspiration for your own
										digital initiatives. The Africa Digital
										Conference and Award is a
										not-to-be-missed event for anyone
										passionate about the digital future of
										Africa. Join us as we explore the
										limitless possibilities, share
										knowledge, and celebrate the remarkable
										achievements in Africa's digital
										transformation. Together, let's shape
										the future of the continent and create a
										thriving digital ecosystem that empowers
										individuals, businesses, and communities
										alike.
									</p>
								</article>
								<div className='flex-1'>
									<img src={cong2} alt='about' />
								</div>
							</div>
						</Animation>
					</div>
				</div>
			</Animation>
			<Animation>
				<Schedule />
			</Animation>
			<Animation>
				<Partners second />
			</Animation>
			<Animation>
				<Footer />
			</Animation>
		</div>
	);
};

export default About;
