import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FcMenu } from 'react-icons/fc';
import logo from '../assets/imgs/logo.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'animate.css';
import { useNavigate } from 'react-router-dom';
const Nav = () => {
	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();
	const [menu, setMenu] = useState(false);
	const [dropDown, setDropDown] = useState(false);
	const [votedropDown, setVoteDropDown] = useState(false);
	const [enterDropDown, setEnterDropDown] = useState(false);
	const handleMenu = () => {
		if (menu) {
			setMenu(false);
		} else {
			setMenu(true);
		}
	};
	const handleEnterDropDown = () => {
		if (enterDropDown) {
			setEnterDropDown(false);
			setDropDown(false);
			setVoteDropDown(false);
		} else {
			setEnterDropDown(true);
		}
	};
	const handleDropDown = () => {
		if (dropDown) {
			setDropDown(false);
			setEnterDropDown(false);
			setVoteDropDown(false);
		} else {
			setDropDown(true);
		}
	};
	const handleVoteDropdown = () => {
		if (votedropDown) {
			setVoteDropDown(false);
			setEnterDropDown(false);
			setDropDown(false);
		} else {
			setVoteDropDown(true);
		}
	};

	// ========= uncomment this if nomination is closed  =====
	/*
  const handleClose = () => {
    Swal.fire({
      title: "SORRY!",
      text: "Nomination for the year 2022 has been closed",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
    navigate("/");
    window.location.reload();
  };
  */
	return (
		<nav>
			<div className='md:py-3 md:pl-[80px] md:pr-[100px] px-[18px] bg-[#100F0F] text-white h-[80px] md:h-[104px] w-screen md:flex md:items-center md:justify-between text-[20px]'>
				<div className='flex justify-between items-center h-full cursor-pointer mx-3'>
					<Link
						className='text-[20px] hover:text-primary duration-500 '
						to='/'
					>
						<img
							src={logo}
							className='h-[60px] md:h-auto'
							alt='logo'
						/>
					</Link>
					<FcMenu
						color='#ffffff'
						size='40'
						className='text-white md:hidden'
						onClick={handleMenu}
					/>
					{/* <img src={icon} alt="hamburger" onClick={handleMenu} className=' max-w-[40px] h-[40px]' /> */}
				</div>
				<ul
					className={`${
						menu
							? 'top-[104px] opacity-100 pb-6 z-10 h-screen'
							: 'hidden'
					} md:justify-between md:flex md:items-center md:static absolute md:bg-transparent bg-black w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 font-normal text-[20px]`}
				>
					<li className='md:ml-[45px] md:mr-[27px] my-3 md:my-0'>
						<Link
							className='text-[20px] hover:text-text_primary duration-500 '
							to='/'
						>
							Home
						</Link>
					</li>
					<li
						className={`${
							dropDown ? 'mt-[10px] md:mt-0' : 'my-3 md:my-0'
						} md:mr-[30px]  relative `}
						onMouseEnter={handleDropDown}
						onClick={() => setDropDown(!dropDown)}
						onMouseLeave={() => setDropDown(false)}
					>
						<span className='cursor-pointer flex items-center  '>
							<span className='mr-2'>ADCA </span>{' '}
							<MdKeyboardArrowDown />
						</span>
						{/* <Link className="text-[20px] hover:text-text_primary duration-500" to="/adca">The ADCA</Link> */}
						<ul
							className={`${
								dropDown
									? 'top-[25px] opacity-100 pb-6 z-20 '
									: 'hidden'
							} absolute  bg-black text-white py-4  px-6 font-normal text-xl md:min-w-[30vw]`}
							style={{ zIndex: 2 }}
						>
							{' '}
							<li className='mb-3 '>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/adca'
								>
									About ADCA
								</Link>
							</li>
							<li className='mb-3 '>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/adca-schedule'
								>
									ADCA Schedule
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/organizer'
								>
									Organizer
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/advice-board'
								>
									Advisory Board
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/award-categories'
								>
									Categories
								</Link>
							</li>
							<div className='flex gap-[20px] mt-[20px] border-t border-fuchsia-200 pt-4'>
								<div className='flex-1'>
									<li className='mb-3 '>
										<Link
											className='text-xl hover:text-primary_blue-200 duration-500'
											to='/adca-2023'
										>
											ADCA 2023
										</Link>
									</li>

									<li className='mb-3'>
										<Link
											className='text-xl hover:text-primary_blue-200 duration-500'
											to='/event-speakers'
										>
											ADCA 2023 Speakers
										</Link>
									</li>
								</div>
								<div className='flex-1'>
									<li className='mb-3 '>
										<Link
											className='text-xl hover:text-primary_blue-200 duration-500'
											to='/adca-2024'
										>
											ADCA 2024
										</Link>
									</li>

									<li className='mb-3'>
										<Link
											className='text-xl hover:text-primary_blue-200 duration-500'
											to='/event-speakers-2023'
										>
											ADCA 2024 Speakers
										</Link>
									</li>
								</div>
							</div>
						</ul>
					</li>
					{/* <li
						className={`${
							dropDown ? 'mt-[10px] md:mt-0' : 'my-3 md:my-0'
						} md:mr-[30px]  relative `}
						onMouseEnter={handleDropDown}
						onClick={() => setDropDown(!dropDown)}
						onMouseLeave={() => setDropDown(false)}
					>
						<span className='cursor-pointer flex items-center  '>
							<span className='mr-2'>ADCA </span>{' '}
							<MdKeyboardArrowDown />
						</span>
						<ul
							className={`${
								dropDown
									? 'top-[25px] opacity-100 pb-6 z-20 '
									: 'hidden'
							} absolute  bg-black text-white py-4  px-4 font-normal text-xl min-w-[200px]`}
							style={{ zIndex: 2 }}
						>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/adca'
								>
									ADCA
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/organizer'
								>
									Organizer
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/advice-board'
								>
									Advisory Board
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/award-categories'
								>
									Categories
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/event-speakers'
								>
									ADCA Speakers
								</Link>
							</li>
						</ul>
					</li> */}
					{/* <li className="md:mr-[30px] my-3 md:my-0">
              <Link className="text-[20px] hover:text-text_primary duration-500" to="/entry">Nomination/Entry</Link>
          </li> */}
					<li
						className={`${
							enterDropDown ? 'mt-80px md:mt-0' : 'my-3 md:my-0'
						} md:mr-[30px]  relative `}
						onMouseEnter={handleEnterDropDown}
						onClick={() => setEnterDropDown(!enterDropDown)}
						onMouseLeave={() => setEnterDropDown(false)}
					>
						<span className='cursor-pointer flex items-center  '>
							<span className='mr-2'>Nomination/Entry </span>{' '}
							<MdKeyboardArrowDown />
						</span>
						<ul
							className={`${
								enterDropDown
									? 'top-[25px] opacity-100 pb-6 z-20 '
									: 'hidden'
							} absolute  bg-black text-white left-0 py-4  px-4 font-normal text-xl min-w-[150px]`}
						>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-100 duration-500'
									to='/entry'
								>
									Who Can Enter
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-100 duration-500'
									to='/how-to-enter'
								>
									How to enter
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/criteria'
								>
									Criteria
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/rules'
								>
									Rules
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-xl hover:text-primary_blue-200 duration-500'
									to='/nominees'
								>
									Nominees
								</Link>
							</li>
						</ul>
					</li>
					<li
						className={`${
							votedropDown ? 'mt-[20px] md:mt-0' : 'my-3 md:my-0'
						} md:mr-[30px]  relative `}
						onMouseEnter={handleVoteDropdown}
						onClick={() => setVoteDropDown(!votedropDown)}
						onMouseLeave={() => setVoteDropDown(false)}
					>
						<span className='cursor-pointer flex items-center  '>
							<span className='mr-2'>Portal </span>{' '}
							<MdKeyboardArrowDown />
						</span>
						{/* <Link className="text-[20px] hover:text-text_primary duration-500" to="/adca">The ADCA</Link> */}
						<ul
							className={`${
								votedropDown
									? 'top-[25px] opacity-100 pb-6 z-20 '
									: 'hidden'
							} absolute  bg-black text-white py-4  px-4 font-normal text-xl min-w-[200px]`}
						>
							<li className='mb-3'>
								<Link
									className='text-[20px] hover:text-text_primary duration-500'
									to='/nomination-portal'
									// onClick={handleClose}
								>
									Nomination Portal
								</Link>
							</li>
							<li className='mb-3'>
								<Link
									className='text-[20px] hover:text-text_primary duration-500'
									to='/voting-portal-categories'
								>
									Voting Portal
								</Link>
							</li>
							{/* <li className="mb-3">
                <Link
                  className="text-[20px] hover:text-text_primary duration-500"
                  to="/"
                >
                  Voting Portal
                </Link>
              </li> */}
						</ul>
					</li>
					<li className=' my-3 md:my-0'>
						<Link
							className='text-[20px] hover:text-text_primary duration-500'
							to='/how-we-judge'
						>
							How We Judge
						</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default Nav;
