import React from 'react';
import Nav from '../../Components/Nav';
import headeimg from '../../assets/imgs/header.png';
import Schedule from '../../Components/Schedule';
import Milestone from '../../Components/Milestone';
import Partners from '../../Components/Partners';
import Footer from '../../Components/Footer';
import Gallery from '../../Components/Gallery';
import award from '../../assets/imgs/award.png';
import cong3 from '../../assets/imgs/award/new/cong3.jpg';

import { Link } from 'react-router-dom';
import CustomCarousel from '../../Components/common/CustomCarousel';
import Animation from '../../Components/common/Animation';

const Home = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='overflow-hidden'>
			<Nav />
			{/* <header className='header flex  items-center h-[90vh]'> */}
			{/* <div className='w-5/6 md:w-[700px] mx-auto text-center'>
					<h3 className='font-[600] text-[27px] md:text-[48px] text-white leading-[56px] mb-[40px]'>
						AFRICAN DIGITAL CONFERENCE AND AWARDS (ADCA)
					</h3>
					<Link to='/reservation'>
						<button className='md:min-w-[400px] md:h-[56px] py-[12px] px-[13px] md:px-[16px] bg-primary font-[600] text-[16px] md:text-[22px] rounded-full'>
							RESERVE A SEAT
						</button>
					</Link>
				</div> */}
			{/* </header> */}
			<div className=''>
				<CustomCarousel />
			</div>
			<Animation
				style='fade-left'
				placement='center-center'
				className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
			>
				<div className=' pt-[64px] flex flex-col md:flex-row gap-x-[20px] justify-between px-[30px]'>
					<article className='mb-[17px]'>
						<h1 className='font-[600] text-[20px] md:text-[30px]'>
							ABOUT THE AWARD
						</h1>
						<div className='w-[64px] h-[2px] mt-[3px] bg-primary'></div>
						<p className='leading-[32px] font-[500] text-[18px] md:text-[20px] mt-[18px] md:mt-[32px] text-left  md:w-[624px] mb-[17px] md:mb-0'>
							Welcome to the Africa Digital Conference and Award,
							where we celebrate the vibrant and rapidly evolving
							digital landscape across the African continent. This
							groundbreaking event brings together industry
							leaders, innovators, and visionaries to explore the
							latest trends, share insights, and recognize
							outstanding achievements in the digital realm. Get
							ready for a dynamic program that includes
							captivating keynotes, engaging workshops,
							enlightening fireside chats, invaluable networking
							opportunities, prestigious awards and recognition
							ceremonies, and an exciting exhibition and
							showcasing of cutting-edge technologies.
						</p>
						<Link to='/adca'>
							<button className='mt-[20px] md:mt-[52px] py-[12px] px-[26px] md:px-[26px] bg-transparent border border-primary rounded-full font-[600] text-[18px] md:text-[24px] hover:bg-primary hover:text-white'>
								READ MORE{' '}
							</button>
						</Link>
					</article>
					<aside className='my-10px'>
						<img
							src={cong3}
							alt='award'
							className='w-full h-full'
						/>
					</aside>
				</div>
			</Animation>
			<Animation
				style='fade-right'
				placement='center-center'
				className='md:w-[550px] flex flex-col justify-center items-center md:items-start mb-[40px] xl:mb-0'
			>
				<Schedule />
			</Animation>
			<Animation
				style='fade-left'
				placement='center-center'
				className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
			>
				<Milestone />
			</Animation>
			<Animation
				style='fade-right'
				placement='center-center'
				className='md:flex-[45%] flex flex-col justify-center items-center md:items-start mb-[50px] xl:mb-0'
			>
				<Gallery />
			</Animation>
			<Partners />
			<Footer />
		</div>
	);
};

export default Home;
