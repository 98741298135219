import React from 'react';
import About from '../../Components/About';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Partners from '../../Components/Partners';
import Schedule from '../../Components/Schedule';
import frame from '../../assets/imgs/award.png';
import Animation from '../../Components/Animation';

const AdvisoryBoard = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Nav />
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[30px] '>
						ADVISORY BOARD
					</h1>
					<div className='w-[96px] h-[5px] mt-[8px] bg-primary'></div>
					<div className='block md:flex'>
						<article className='w-5/6 mx-auto md:mx-0 md:w-[817px] md:mr-[66px] mb-[30px] md:mb-0'>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-left mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								Our Advisory Board consists of notable
								technology experts and personalities who are
								knowledgeable in the technology ecosystem. They
								exist to provide support, guidance, advice, and
								offer support throughout the Awards and
								Conference.
							</p>
						</article>
						<span className=''>
							<img src={frame} alt='about' />
						</span>
					</div>
				</div>
			</Animation>
			<Partners />
			<Footer />
		</div>
	);
};

export default AdvisoryBoard;
