import React from 'react';
import About from '../../Components/About';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Partners from '../../Components/Partners';
import Schedule from '../../Components/Schedule';
import frame from '../../assets/imgs/award.png';
import Animation from '../../Components/Animation';

const Organizer = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Nav />
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px]'>
						THE ORGANIZERS
					</h1>
					<div className='w-[64px] h-[5px] mt-[8px] bg-primary'></div>
					<div className='block md:flex'>
						<article className='w-5/6 mx-auto md:mx-0 md:w-[817px] md:mr-[66px] mb-[30px] md:mb-0'>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-justify mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								The Africa Digital Conference and Awards is
								proudly organised by Alpha Blue Foundation
								(ABF). Alpha Blue Foundation (ABF) is a
								tech-based non-profit organisation launched to
								empower Africans with digital tech skills to
								ensure they contribute to the technological and
								socio-economic advancement of Africa and the
								world.{' '}
							</p>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-justify mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								ABF has implemented projects that empower
								individuals with the skills they need to become
								critical thinkers, problem solvers, and agents
								of sustainable development in our
								technologically advancing world.{' '}
							</p>
							<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px] mt-6'>
								OUR MISSION
							</h1>
							<div className='w-[96px] h-[5px] mt-[8px] bg-primary'></div>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-justify mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								Our mission is to empower Africans with digital
								tech skills that will promote a technologically
								thriving African society.{' '}
							</p>
							<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px] mt-6'>
								OUR VISION
							</h1>
							<div className='w-[96px] h-[5px] mt-[8px] bg-primary'></div>
							<p
								className='font-[400] text-[18px] md:text-[24px] text-justify mt-[32px]'
								style={{ lineHeight: '32px' }}
							>
								We envision an African society that is
								technologically developed, where poverty is at
								its minimal level and sustainable development,
								financial freedom, and economic prosperity.
							</p>
						</article>

						<span className='mt-[128px]'>
							<img src={frame} alt='about' />
						</span>
					</div>
				</div>
			</Animation>
			<Schedule />
			<Partners second />
			<Footer />
		</div>
	);
};

export default Organizer;
