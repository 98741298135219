import React from "react";

const AdcaRegistration = () => {
  return (
    <div className="flex justify-center items-center overflow-hidden pb- ">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeGbd_Rp_cYtwRHysiqD-1Z3KDL0c0y_a1vLf4Hxkb4E1Y8Mg/viewform?embedded=true"
        width="100%"
        // height="1157"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        className="overflow-hidden h-[100vh]"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default AdcaRegistration;
