import React, { useState } from 'react';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'animate.css';
import { url } from '../../url';
import { MdArrowDownward } from 'react-icons/md';
import Animation from '../../Components/Animation';
// import { PaystackButton } from "react-paystack";
import { usePaystackPayment } from 'react-paystack';
const pk = process.env.REACT_APP_API;
// require("dotenv").config();
const BothEvent = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		// setOpen(true)
	}, []);
	let filled = false;
	const [state, setState] = useState([
		{
			name: '',
			email: '',
			country: '',
			state: '',
			city: '',
			number: '',
			gender: '',
			organization: '',
			position: '',
			reservation: '',
			workshop: '',
			mode: '',
		},
	]);
	if (
		state.name &&
		state.email &&
		state.country &&
		state.state &&
		state.city &&
		state.number &&
		state.gender &&
		state.mode &&
		state.workshop
	) {
		filled = true;
	}
	const PaystackProps = {
		email: state.email,
		amount: Number(state.reservation),
		metadata: {
			name: state.name,
			phone: state.number,
		},
		// publicKey: "pk_test_d1729f93a68bc6c5ad1c5f9c7dd1888a1fd7b0ef",
		publicKey: process.env.REACT_APP_API,
		text: 'Pay Now',
		onSuccess: () => createInfo(),
		onClose: () => handlePaymentErrorAlert(),
	};
	const MySwal = withReactContent(Swal);
	const handlePaymentErrorAlert = () => {
		Swal.fire({
			title: 'Error',
			text: 'Payment not successfull kindly try again',
			showClass: {
				popup: 'animate__animated animate__fadeInDown',
			},
			hideClass: {
				popup: 'animate__animated animate__fadeOutUp',
			},
		});
	};
	const handleFormErrorAlert = () => {
		Swal.fire({
			title: 'Error',
			text: 'Form Not Submited',
			showClass: {
				popup: 'animate__animated animate__fadeInDown',
			},
			hideClass: {
				popup: 'animate__animated animate__fadeOutUp',
			},
		});
	};
	const handleAlert = () => {
		Swal.fire({
			title: 'THANK YOU!',
			text: 'For filling out this form. We will contact you via mail shortly.',
			showClass: {
				popup: 'animate__animated animate__fadeInDown',
			},
			hideClass: {
				popup: 'animate__animated animate__fadeOutUp',
			},
		});
	};
	const handleChange = (evt) => {
		const value =
			evt.target.type === 'checkbox'
				? evt.target.checked
				: evt.target.value;
		setState({
			...state,
			[evt.target.name]: value,
		});
	};
	const config = {
		metdata: {
			name: state.name,
			phone: state.number,
		},
		email: state.email,
		amount: Number(state.reservation),
		publicKey: pk,
	};

	const onSuccess = (reference) => {
		createInfo();
	};

	// you can call this function anything
	const onClose = () => {
		handlePaymentErrorAlert();
	};

	const PaystackPayment = () => {
		const initializePayment = usePaystackPayment(config);
		return (
			<div>
				<button
					type='button'
					onClick={() => {
						initializePayment(onSuccess, onClose);
					}}
					className='h-[56px] font-[600] text-[20px] md:text-[24px] text-black w-full bg-primary hover:bg-primary mt-[36px] rounded-full'
				>
					Pay Now
				</button>
			</div>
		);
	};
	const createInfo = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/json');
		let response = await fetch(`${url}reservation/both`, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify({
				personalInfo: {
					fullName: state.name,
					email: state.email,
					country: state.country,
					state: state.state,
					city: state.city,
					phoneNumber: state.number,
					gender: state.gender,
				},
				organizationInfo: {
					name: state.organization,
					jobTitle: state.position,
				},
				seatReservation:
					state.mode === 'Virtual' ? 0 : state.reservation,
				workshopPreference: state.workshop,
				attendancePreference: state.mode,
			}),
		});
		if (response.status === 400) handleFormErrorAlert();
		else {
			setState('');
			Array.from(document.querySelectorAll('input')).forEach(
				(input) => (input.value = '')
			);
			handleAlert();
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		createInfo();
	};
	return (
		<>
			<Nav />
			<Animation>
				<div className='p-[20px] md:p-[60px] md:px-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[40px] uppercase'>
						reservation
					</h1>
					<div className='w-[96px] h-[5px] mt-[8px] mb-[32px] bg-primary'></div>
					<h3 className='mb-[32px] font-[600] text-[24px] leading-6'>
						THE AFRICA DIGITAL AWARDS CONFERENCE AND GALA NIGHT
					</h3>
					<h3 className='mb-[8px] font-[600] text-[18px] leading-6'>
						PERSONAL INFO
					</h3>
					<p className='font-[400] text-[16px] leading-6'>
						KINDLY INPUT YOUR PERSONAL INFO
					</p>
					<form>
						<div className='form-inp mt-[24px] md:w-full'>
							<label
								htmlFor='name'
								className='font-[400] text-[20px]  md:text-[24px]'
							>
								Full Name{' '}
								<span className='text-red-500'> *</span>
							</label>
							<input
								required
								type='text'
								id='name'
								className='w-full h-[56px] border-none mt-[8px] p-[16px]'
								style={{
									background: '#FFFFFF',
									boxShadow:
										' 4px 4px 8px rgba(0, 0, 0, 0.16)',
									borderRadius: '8px',
								}}
								placeholder='full name'
								name='name'
								value={state.name}
								onChange={handleChange}
							/>
						</div>
						<div className='md:flex md:gap-[122px]'>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<label
									htmlFor='email'
									className='font-[400] text-[20px]  md:text-[24px]'
								>
									Email Address{' '}
									<span className='text-red-500'> *</span>
								</label>
								<input
									required
									type='text'
									id='email'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='email'
									name='email'
									value={state.email}
									onChange={handleChange}
								/>
							</div>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<label
									htmlFor='country'
									className='font-[400] text-[20px]  md:text-[24px]'
								>
									Country{' '}
									<span className='text-red-500'> *</span>
								</label>
								<input
									required
									type='tel'
									id='country'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='Country'
									name='country'
									value={state.country}
									onChange={handleChange}
								/>
							</div>
						</div>
						{/* END OF FORM ROW */}
						<div className='md:flex md:gap-[122px]'>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<label
									htmlFor='state'
									className='font-[400] text-[20px]  md:text-[24px]'
								>
									State{' '}
									<span className='text-red-500'> *</span>
								</label>
								<input
									required
									type='text'
									id='state'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='Plateau'
									name='state'
									value={state.state}
									onChange={handleChange}
								/>
							</div>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<label
									htmlFor='city'
									className='font-[400] text-[20px]  md:text-[24px]'
								>
									city{' '}
									<span className='text-red-500'> *</span>
								</label>
								<input
									required
									type='text'
									id='city'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='Jos'
									name='city'
									value={state.city}
									onChange={handleChange}
								/>
							</div>
						</div>
						{/* END OF FORM ROW */}
						<div className='md:flex md:gap-[122px]'>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<label
									htmlFor='number'
									className='font-[400] text-[20px]  md:text-[24px]'
								>
									Mobile Phone No{' '}
									<span className='text-red-500'> *</span>
								</label>
								<input
									required
									type='text'
									id='number'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='+2348012345567'
									name='number'
									value={state.number}
									onChange={handleChange}
								/>
							</div>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<p className='font-[400] text-[20px]  md:text-[24px] mb-3'>
									Gender{' '}
									<span className='text-red-500'> *</span>
								</p>
								<div className='mb-[10px]'>
									<input
										required
										type='radio'
										name='gender'
										id='Male'
										value='Male'
										checked={state.gender === 'Male'}
										onChange={handleChange}
									/>
									<label
										htmlFor='Male'
										className='ml-[12px] font-[400] text-[20px] leading-6'
									>
										Male
									</label>
								</div>
								<div>
									<input
										required
										type='radio'
										name='gender'
										id='Female'
										value='Female'
										checked={state.gender === 'Female'}
										onChange={handleChange}
									/>
									<label
										htmlFor='Female'
										className='ml-[12px] font-[400] text-[20px] leading-6'
									>
										Female
									</label>
								</div>
							</div>
						</div>
						<h3 className='mt-[48px] mb-[8px] font-[600] text-[20px] md:text-[24px] leading-6'>
							Organizational Info.
						</h3>
						<p className='mb-[32px] font-[400] text-[16px] leading-6'>
							If you are representing an organisation, kindly fill
							in this section.
						</p>
						{/* END OF FORM ROW */}
						<div className='md:flex md:gap-[122px]'>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<label
									htmlFor='organization'
									className='font-[400] text-[20px]  md:text-[24px]'
								>
									Name of Organization
								</label>
								<input
									type='text'
									id='organization'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='Name of Organization'
									name='organization'
									value={state.organization}
									onChange={handleChange}
								/>
							</div>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<label
									htmlFor='postion'
									className='font-[400] text-[20px]  md:text-[24px]'
								>
									Position/Job Title
								</label>
								<input
									type='text'
									id='postion'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='C.E.O'
									name='postion'
									value={state.postion}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className=''>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<p className='font-[600] text-[20px]  md:text-[24px] mb-3'>
									Which of the workshops would you like to
									attend?{' '}
									<span className='text-red-500'> *</span>
								</p>
								<div className='mb-[10px]'>
									<input
										required
										type='radio'
										name='workshop'
										id='workone'
										value='GIG Economy Job creation and Economic Growth for Africa'
										checked={
											state.workshop ===
											'GIG Economy Job creation and Economic Growth for Africa'
										}
										onChange={handleChange}
									/>
									<label
										htmlFor='workone'
										className='ml-[12px] font-[400] text-[20px] leading-6'
									>
										GIG Economy Job creation and Economic
										Growth for Africa
									</label>
								</div>
								<div>
									<input
										required
										type='radio'
										name='workshop'
										id='worktwo'
										value='GIG Skills for GIG Economy'
										checked={
											state.workshop ===
											'GIG Skills for GIG Economy'
										}
										onChange={handleChange}
									/>
									<label
										htmlFor='worktwo'
										className='ml-[12px] font-[400] text-[20px] leading-6'
									>
										GIG Skills for GIG Economy
									</label>
								</div>
							</div>
							<div className='form-inp mt-[24px] md:w-[616px]'>
								<p className='font-[600] text-[20px]  md:text-[24px] mb-3'>
									How would you like to attend this event?{' '}
									<span className='text-red-500'> *</span>
								</p>
								{/* <div className="mb-[10px]">
                  <input
                    required
                    type="radio"
                    name="mode"
                    id="Physical"
                    value="Physical"
                    checked={state.mode === "Physical"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Physical"
                    className="ml-[12px] font-[400] text-[20px] leading-6"
                  >
                    Physical
                  </label>
                </div> */}
								<div>
									<input
										required
										type='radio'
										name='mode'
										id='Virtual'
										value='Virtual'
										checked={state.mode === 'Virtual'}
										onChange={handleChange}
									/>
									<label
										htmlFor='Virtual'
										className='ml-[12px] font-[400] text-[20px] leading-6'
									>
										Virtual
									</label>
								</div>
							</div>
						</div>
						<div className=''>
							{state.mode === 'Physical' && (
								<div className='form-inp mt-[48px] mb-[24px] md:w-[616px]'>
									<p className='font-[600] text-[20px]  md:text-[24px] mb-3'>
										Seat Reservation{' '}
										<span className='text-red-500'> *</span>
									</p>
									<div className='mb-[16px]'>
										<input
											required
											type='radio'
											name='reservation'
											id='regular'
											value='500000'
											checked={
												state.reservation === '500000'
											}
											onChange={handleChange}
										/>
										<label
											htmlFor='regular'
											className='ml-[12px] font-[600] text-[16px] leading-6'
										>
											Regular N5,000
										</label>
									</div>
									<div className='mb-[16px]'>
										<input
											required
											type='radio'
											name='reservation'
											id='vip'
											value='1000000'
											checked={
												state.reservation === '1000000'
											}
											onChange={handleChange}
										/>
										<label
											htmlFor='vip'
											className='ml-[12px] font-[600] text-[16px] leading-6'
										>
											VIP N10,000
										</label>
									</div>
									<div className='mb-[16px]'>
										<input
											required
											type='radio'
											name='reservation'
											id='vvip'
											value='1500000'
											checked={
												state.reservation === '1500000'
											}
											onChange={handleChange}
										/>
										<label
											htmlFor='vvip'
											className='ml-[12px] font-[600] text-[16px] leading-6'
										>
											VVIP N15,000
										</label>
									</div>
									{/* <div className="mb-[16px]">
                  <input
                    required
                    type="radio"
                    name="reservation"
                    id="tableof10"
                    value="25000000"
                    checked={state.reservation === "25000000"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="tableof10"
                    className="ml-[12px] font-[600] text-[16px] leading-6"
                  >
                    Table of Ten N250,000
                  </label>
                </div> */}
								</div>
							)}
						</div>
						<div className='w-4/5 md:w-[332px] mx-auto md:mx-0'>
							{filled &&
							state.mode === 'Physical' &&
							state.reservation ? (
								// <PaystackButton
								//   {...PaystackProps}
								//   className="h-[56px] font-[600] text-[20px] md:text-[24px] text-black w-full bg-primary hover:bg-primary mt-[36px] rounded-full"
								// />
								<PaystackPayment />
							) : (
								<button
									onClick={handleSubmit}
									type='button'
									className='h-[56px] font-[600] text-[16px] md:text-[24px] text-white w-full bg-green-400 hover:bg-primary mt-[36px] rounded-full'
								>
									{!filled
										? 'fill the required info'
										: 'Submit'}
								</button>
							)}
						</div>
					</form>
					{/* <div className='flex flex-col justify-center items-center mt-[70px]'>
						<MdArrowDownward
							size={40}
							className='animate-bounce text-primary'
						/>
						<h3 className='text-italic font-bold text-xl md:text-2xl text-center'>
							To get a Table of Ten kindly contact +2348131983791,
							or +2347013411186
						</h3>
					</div> */}
				</div>
			</Animation>
			<Footer />
		</>
	);
};

export default BothEvent;
