import Reac, { useState, useEffect } from 'react';
import ScheduleCard from './ScheduleCard';
import { url } from '../url';
import dateFormat, { masks } from 'dateformat';

const Schedule = () => {
	const [nomStartDate, setNomStartDate] = useState('');
	const [nomEndDate, setNomEndDate] = useState('');
	const [votingStartDate, setVotingEndDate] = useState('');
	const [votingEndDate, setVotingStartDate] = useState('');
	const [eventDate, setEventDate] = useState('');
	const [venue, setVenue] = useState('');
	const fetchNomDate = async () => {
		const response = await fetch(`${url}info/nomDate`);
		const data = await response.json();
		setNomStartDate(data[0].startDate);
		setNomEndDate(data[0].endDate);
		// console.log(data[0].nomDate);
	};
	const fetchVotingDate = async () => {
		const response = await fetch(`${url}info/votingDate`);
		const data = await response.json();
		setVotingStartDate(data[0].startDate);
		setVotingEndDate(data[0].endDate);
	};
	const fetchEventDate = async () => {
		const response = await fetch(`${url}info/eventDate`);
		const data = await response.json();
		setEventDate(data[0].eventDate);
	};
	const fetchEventVenue = async () => {
		const response = await fetch(`${url}info/eventVenue`);
		const data = await response.json();
		setVenue(data[0].venue);
	};
	useEffect(() => {
		fetchNomDate();
		fetchVotingDate();
		fetchEventVenue();
		fetchEventDate();

		return () => {
			fetchNomDate();
		};
	}, []);
	return (
		<div className='w-full md:min-h-[376px] md:py-[64px] pb-[40px] px-[20px] md:px-[40px] bg-[#F8F6F5] '>
			<h1 className='font-[600] text-[20px] md:text-[30px] mb-[8px]'>
				{new Date().getFullYear()} ADCA EVENT SCHEDULE
			</h1>
			<div className='w-[96px] h-[2px] bg-primary'></div>
			<div className='block md:flex md:gap-[104px]'>
				{/* <ScheduleCard
          text="NOMINATION/SCREENING"
          date={`From ${dateFormat(nomStartDate, "fullDate")} to ${dateFormat(
            nomEndDate,
            "fullDate"
          )} (CLOSED)`}
          other="To nominate"
          link
          address="nomination-portal"
        />
        <ScheduleCard
          text="VOTING PROCESS"
          date={`From ${dateFormat(votingEndDate, "fullDate")} to ${dateFormat(
            votingStartDate,
            "fullDate"
          )}`}
          other="To vote"
          link
          address="voting-portal-categories"
        /> */}
				<ScheduleCard
					text='NOMINATION/SCREENING'
					date={`${dateFormat(nomStartDate, 'fullDate')} (Closed)`}
					other='To nominate'
					link
					address='nomination-portal'
				/>
				<ScheduleCard
					text='VOTING PROCESS'
					date={`${dateFormat(votingEndDate, 'fullDate')} (Closed)`}
					other='To vote'
					link
					address='voting-portal-categories'
				/>
				<ScheduleCard
					text='ADCA CEREMONY'
					date={dateFormat(eventDate, 'fullDate')}
					other={`The event will hold in ${
						venue && venue
					} and attendance is based on invitation`}
				/>
			</div>
		</div>
	);
};

export default Schedule;
