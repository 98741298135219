import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import galery1 from '../../assets/imgs/galery1.png';
// import galery2 from '../../assets/imgs/galery2.png';
// import galery3 from '../../assets/imgs/galery3.png';
import one from '../../assets/imgs/award/award9.JPG';
import two from '../../assets/imgs/award/two.JPG';
import three from '../../assets/imgs/award/three.JPG';
import four from '../../assets/imgs/award/four.JPG';
import five from '../../assets/imgs/award/five.JPG';
import six from '../../assets/imgs/award/six.JPG';
import seven from '../../assets/imgs/award/seven.JPG';
import eight from '../../assets/imgs/award/eight.JPG';
import nine from '../../assets/imgs/award/nine.JPG';
import ten from '../../assets/imgs/award/ten.JPG';
import aw from '../../assets/imgs/award/aw.JPG';
import award1 from '../../assets/imgs/award/award1.JPG';
import award2 from '../../assets/imgs/award/award2.JPG';
import award4 from '../../assets/imgs/award/award4.JPG';
import award5 from '../../assets/imgs/award/award5.JPG';
import award6 from '../../assets/imgs/award/award6.JPG';
import award7 from '../../assets/imgs/award/award7.JPG';
import award8 from '../../assets/imgs/award/award8.JPG';
import speaker2 from '../../assets/imgs/award/new/speaker2.jpg';
import speaker1 from '../../assets/imgs/award/new/speaker1.jpg';
import speaker3 from '../../assets/imgs/award/new/speaker3.jpg';
import cong1 from '../../assets/imgs/award/new/cong1.jpg';
import cong2 from '../../assets/imgs/award/new/cong2.jpg';
import cong3 from '../../assets/imgs/award/new/cong3.jpg';
import cong4 from '../../assets/imgs/award/new/cong4.jpg';
import cong5 from '../../assets/imgs/award/new/cong5.jpg';
import cong7 from '../../assets/imgs/award/new/cong7.jpg';
import cong8 from '../../assets/imgs/award/new/cong8.jpg';
import cong9 from '../../assets/imgs/award/new/cong9.jpg';
import cong10 from '../../assets/imgs/award/new/cong10.jpg';
import cong11 from '../../assets/imgs/award/new/cong11.jpg';
import CustomModal from '../../Components/common/CustomModal';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import Partners from '../../Components/Partners';

const images = [
	one,
	two,
	three,
	four,
	five,
	six,
	seven,
	eight,
	nine,
	ten,
	aw,
	award1,
	award2,
	award4,
	award5,
	award6,
	award7,
	award8,
	speaker2,
	speaker3,
	cong1,
	cong2,
	cong3,
	cong4,
	cong5,
	cong7,
	cong8,
	cong9,
	cong10,
	cong11,
];

const PictureGallery = () => {
	const [selectedImage, setSelectedImage] = useState(null);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const openModal = (image) => {
		console.log('click');
		setSelectedImage(image);
	};

	const closeModal = () => {
		setSelectedImage(null);
	};

	return (
		<>
			<Nav />

			<div className='px-5'>
				{/* <div className='picture-grid'> */}
				<div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-[32px]'>
					{images.map((image, index) => (
						<div
							key={index}
							className='picture-item'
							// onClick={() => openModal(image)}
							title='Click to view'
						>
							<LazyLoadImage
								src={image}
								alt='Image Description'
								effect='blur'
								placeholderSrc={one}
								onClick={() => openModal(image)}
								title='Click to view'
							/>
						</div>
					))}
				</div>

				{selectedImage && (
					<div className='modal'>
						<button className='close-button' onClick={closeModal}>
							Close
						</button>
						<CustomModal
							selectedImage={selectedImage}
							closeModal={closeModal}
						/>
					</div>
				)}
			</div>
			<Partners />
			<Footer />
		</>
	);
};

export default PictureGallery;
