import React, { useState, useRef } from 'react';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
// import emailjs from 'emailjs-com'
const isValidEmail = (email) => {
	const regex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(String(email).toLowerCase());
};

const Contact = () => {
	const [fname, setFName] = useState('');
	const [lname, setLName] = useState('');
	const [email, setEmail] = useState('');
	const [number, setNumber] = useState('');
	const [message, setMessage] = useState('');
	const [emailSent, setEmailSent] = useState(false);
	const form = useRef();
	const serviceId = 'service_gcgjwa8';
	const tempalteId = 'template_5kx1orj';
	const public_key = 'v4ZHCDxAsv0CA_FVw';

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const sendEmail = (e) => {
		e.preventDefault();
		// if (fname && lname && email && number && message) {
		//   const user_name = `${fname} ${lname}`
		//   setEmail(isValidEmail(email))

		//   });

		//     setFName('');
		//     setEmail('');
		//     setMessage('');
		//     setEmailSent(true);
		// } else {
		//     alert('Please fill in all fields.');
		// }
	};
	return (
		<>
			<Nav />
			<div className='bg-[#f8f6f5] pt-[64px] pb-[40px] md:pl-[360px]'>
				<h1 className='font-[600] pl-[20px] md:pl-0 text-[30px]'>
					Contact
				</h1>
				<div className='w-[96px] h-[5px] mb-[32px] ml-[20px] md:ml-0 bg-primary'></div>

				<aside className='w-5/6 mx-auto md:mx-0 md:w-[720px] bg-white py-[40px]'>
					{/* <div className="md:max-w-[553px] mx-auto w-full text-center pt-[56px]"> */}
					<p className='w-4/5 md:max-w-[500px] mx-auto text-center text-[18px] md:text-[24px] font-[400] leading-8 '>
						Have any inquiries and want to reach out to us? Fill out
						the form below and drop us a message.
					</p>
					{/* </div> */}
					<div className='form mt-[32px] px-[20px] md:px-[80px]'>
						<form ref={form} onSubmit={sendEmail}>
							<div className='form-inp'>
								<label
									htmlFor='fname'
									className='font-[400] text-[20px] md:text-[18px] md:text-[24px]'
								>
									First Name
								</label>
								<input
									type='text'
									id='fname'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									name='user_first_name'
									placeholder='Kelechi'
									value={fname}
									onChange={(e) => setFName(e.target.value)}
								/>
							</div>
							<div className='form-inp mt-[24px]'>
								<label
									htmlFor='lname'
									className='font-[400] text-[20px] md:text-[18px] md:text-[24px]'
								>
									Last Name
								</label>
								<input
									type='text'
									id='lname'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='Femmyte'
									name='user_last_name'
									value={lname}
									onChange={(e) => setLName(e.target.value)}
								/>
							</div>
							<div className='form-inp mt-[24px]'>
								<label
									htmlFor='email'
									className='font-[400] text-[20px] md:text-[18px] md:text-[24px]'
								>
									Email Address
								</label>
								<input
									type='email'
									id='email'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='femmyte@gmail.com'
									value={email}
									name='user_email'
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className='form-inp mt-[24px]'>
								<label
									htmlFor='number'
									className='font-[400] text-[20px] md:text-[18px] md:text-[24px]'
								>
									Phone Number
								</label>
								<input
									type='tel'
									id='number'
									className='w-full h-[56px] border-none mt-[8px] p-[16px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='08101234567'
									name='user_number'
									value={number}
									onChange={(e) => setNumber(e.target.value)}
								/>
							</div>
							<div className='form-inp mt-[24px]'>
								<textarea
									name='message'
									id='message'
									className='w-full mt-[16px] h-[150px] md:h-[267px] pl-[24px] pt-[32px]'
									style={{
										background: '#FFFFFF',
										boxShadow:
											' 4px 4px 8px rgba(0, 0, 0, 0.16)',
										borderRadius: '8px',
									}}
									placeholder='Your Message'
									value={message}
									onChange={(e) => setMessage(e.target.value)}
								></textarea>
							</div>
							<button className='h-[64px] font-[600] text-[20px] md:text-[18px] md:text-[24px] text-black w-full bg-primary hover:bg-primary mt-[36px] rounded-full'>
								Submit your message
							</button>
							<span className={emailSent ? 'block' : 'hidden'}>
								Thank you for your message, we will be in touch
								in no time!
							</span>
						</form>
					</div>
				</aside>
			</div>
			<Footer />
		</>
	);
};

export default Contact;
