import React, { useState, useEffect } from 'react';
import About from '../../Components/About';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import frame from '../../assets/imgs/award.png';
import Animation from '../../Components/Animation';
import { Link } from 'react-router-dom';
import { url } from '../../url';
import dateFormat, { masks } from 'dateformat';

const Criteria = () => {
	const [nomDate, setNomDate] = useState('');
	const fetchNomDate = async () => {
		const response = await fetch(`${url}info/nomDate`);
		const data = await response.json();
		setNomDate(data[0].startDate);
	};
	useEffect(() => {
		fetchNomDate();

		return () => {
			fetchNomDate();
		};
	}, []);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Nav />
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px]'>
						Nomination Criteria
					</h1>
					<div className='w-[96px] h-[2px] mt-[2px] bg-primary'></div>
					<div className='block md:flex'>
						<article className='w-5/6 mx-auto md:mx-0 md:w-[817px] md:mr-[66px] mb-[30px] md:mb-0'>
							<p className='font-[600] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[20px]'>
								General Nomination Criteria
							</p>
							<ul>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>A
									nomination must be submitted between{' '}
									{dateFormat(nomDate, 'fullDate')}
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									All nominations or entries must be submitted
									online.
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									An incomplete nomination form will
									disqualify the nominee from the voting
									process. Visit{' '}
									<Link
										to='/nomination-portal'
										className='text-primary'
									>
										Nomination Portal
									</Link>{' '}
									to check out the nomination form.
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									The Award Organizer has the right to
									disqualify entries that do not meet the
									entry criteria.
								</li>
							</ul>
							<p className='font-[600] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[20px]'>
								Nomination Criteria for Individuals, CEOs, or
								Tech Experts
							</p>
							<ul>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									ADCA permits self-nomination.
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									It is mandatory for all nominees or entrants
									to have online verifiable track records.
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									Deployed technology products and services
									that serve the public or the industry.
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									If an individual’s entry is related to a
									product or service, it must have been in
									existence for a minimum of one year.
								</li>
							</ul>
							<p className='font-[600] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[20px]'>
								Nomination Criteria for Organisations
							</p>
							<ul>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									Organisations must have a functional website
									to submit their entries.
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									Organisations eligible to submit entries
									must have been in existence for a minimum of
									one year.
								</li>
								<li className='font-[500] text-[16px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									If the organization’s entry is related to a
									product or service, it must have been in
									existence for a minimum of one year.
								</li>
							</ul>
						</article>
						<span className=''>
							<img src={frame} alt='about' />
						</span>
					</div>
				</div>
			</Animation>
			<Footer />
		</div>
	);
};

export default Criteria;
