import React from 'react';
import Animation from '../../Components/Animation';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import Partners from '../../Components/Partners';

const ScheduleCard = ({ title, start, end, date, info }) => {
	return (
		<div className='px-5 w-full'>
			<h1 className='font-[700] text-[20px] md:text-[25px] my-5 text-center'>
				{title}
			</h1>
			<div className='flex flex-col md:flex-row items-start gap-[30px] w-full  border-y border-y-gray-300 py-[20px]'>
				<div className='md:w-[30%]'>
					{start && end && (
						<>
							<p className='font-[600] text-[18px] md:text-[20px] text-left text-black'>
								Start Date: {start}
							</p>
							<p className='font-[600] text-[18px] md:text-[20px] text-left mt-[10px] text-black'>
								End Date: {end}
							</p>
						</>
					)}
					{date && (
						<p className='font-[600] text-[18px] md:text-[20px] text-left mt-[10px] text-black'>
							Date: {date}
						</p>
					)}
				</div>
				<div className='md:w-[70%]'>
					{info.map((item, i) => {
						return (
							<p
								className='font-[400] text-[18px] text-left text-gray-800 mb-[15px]'
								key={i}
							>
								{item}
							</p>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const AdcaSchedule = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='overflow-hidden'>
			<Nav />
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<div className='md:mb-[40px]'>
						<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[40px] text-center'>
							Africa Digital Awards Schedule and Activities
						</h1>
						<div className='mx-auto w-[64px] h-[2px] mt-[3px] bg-primary'></div>
					</div>
					<ScheduleCard
						title='Nomination Period'
						start='September 1st, 2023'
						end='September 30th, 2023'
						info={[
							'During this period, individuals, organizations, and initiatives will be invited to submit nominations for the Africa Digital Awards. Nominations can be made through the official awards website or through a designated nomination portal.',
						]}
					/>
					<ScheduleCard
						title='Screening and Evaluation'
						start='October 1st, 2023'
						end='October 16th, 2023'
						info={[
							'A panel of judges consisting of industry experts and professionals will review the nominations received. They will evaluate the submissions based on criteria such as innovation, excellence, and social impact. The screening process will narrow down the nominations to a shortlist of finalists in each category.',
						]}
					/>
					<ScheduleCard
						title='Public Voting'
						start='October 17th, 202'
						end='November 1st, 2023'
						info={[
							'Once the finalists are selected, the public will have the opportunity to vote for their favorites in each category. Online voting will be open to the public, allowing for broader engagement and participation.',
						]}
					/>
					<ScheduleCard
						title='Final Evaluation'
						start='November 2nd, 2023'
						end='November 7th, 2023'
						info={[
							"The panel of judges will reconvene to review the finalists and consider the public votes. They will carefully evaluate each finalist's accomplishments and determine the winners in each category.",
						]}
					/>
					<ScheduleCard
						title='Pre-Awards Promotion'
						start='November 10th, 2023'
						end='November 25th, 2023'
						info={[
							'The Africa Digital Awards aims to generate excitement and recognition for exceptional achievements in the digital domain. To promote the event and showcase the accomplishments of the finalists and nominees, the following pre-awards promotion activities:',
							'Talk Tech Magazine: Collaborate with the magazine to feature articles, interviews, and profiles of the finalists, providing exposure and recognition.',
							'Media Coverage: Engage with various media outlets to secure coverage of the awards and highlight the impact of digital technologies in Africa.',
							'Interviews and Podcasts: Arrange interviews with industry influencers and journalists to discuss the awards and the role of digital technologies in Africa.',
							'Social Media Campaigns: Utilize social media platforms to create campaigns highlighting the finalists and nominees, encouraging engagement with event-specific hashtags.',
							'Webinars and Panel Discussions: Organize online events featuring the finalists and industry experts to discuss relevant topics.',
							'Influencer Partnerships: Collaborate with influential individuals to create content promoting the awards and encouraging participation.',
							'Email Marketing: Utilize email campaigns to share updates, nomination reminders, and exclusive content with industry professionals and stakeholders.',
						]}
					/>
					<ScheduleCard
						title='Africa Digital Awards Ceremony'
						date='November 25th, 2023'
						info={[
							"The Africa Digital Awards Ceremony will take place on November 25th, 2023, at a prestigious venue. The event will bring together industry leaders, entrepreneurs, innovators, and other key stakeholders in Africa's digital landscape. The ceremony will include various activities such as keynote speeches, panel discussions, and networking opportunities.",
							'During the awards ceremony, the winners in each category will be announced and recognized for their outstanding achievements. Trophies, certificates, and other forms of recognition will be presented to the winners. The event will also provide a platform for winners and finalists to showcase their work, network with industry professionals, and gain exposure.',
						]}
					/>
					<ScheduleCard
						title='Post-Awards Promotion'
						date='November 26th, 2023 onwards'
						info={[
							'Following the awards ceremony, the winners and finalists will be featured in post-awards promotion activities. This may include media coverage, interviews, social media campaigns, and other promotional efforts to highlight their achievements and inspire others in the digital domain.',
						]}
					/>
					{/* <ScheduleCard title='' start='' end='' info={[]} /> */}
				</div>
				;
			</Animation>
			<Animation>
				<Partners second />
			</Animation>
			<Animation>
				<Footer />
			</Animation>
		</div>
	);
};

export default AdcaSchedule;
