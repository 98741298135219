import React from "react";
import About from "../../Components/About";
import Footer from "../../Components/Footer";
import Nav from "../../Components/Nav";
import arrow from "../../assets/imgs/arrow.png";
import { MdArrowDownward } from "react-icons/md";
import Animation from "../../Components/Animation";
import { Link } from "react-router-dom";
const HowWeJudge = () => {
    React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Nav />
      <Animation>
        <div className="pt-[64px] pb-[32px] px-[20px] md:px-[60px]">
          <h1 className="md:max-w-[1164px] font-[600] text-[30px] md:text-[40px]">
            HOW WE JUDGE
          </h1>
          <div className="w-[64px] h-[5px] mt-[8px] bg-primary"></div>
          <div className="pt-[32px]">
            <h3 className="font-[600] text-[18px] md:text-[24px] leading-8 mb-[24px]">
              Our processes are simple.
            </h3>
            <div className="block md:flex gap-[16px]">
              <div className="w-6/7 mx-auto md:mx-0 md:w-[365px] h-[64px] p-[16px] card mb-[16px] text-center">
                <h3 className="font-[600] text-[18px] md:text-[20px] text-white rounded">
                  NOMINATION PROCESS
                </h3>
              </div>
              <img
                src={arrow}
                alt="arrow"
                className="hidden md:block min-w-[77px]"
              />
              <div className="md:hidden flex justify-center">
                <MdArrowDownward
                  size={60}
                  className="text-center md:hidden text-primary"
                />
              </div>
              <div className=" w-6/7 mx-auto md:mx-0 md:w-[365px] h-[64px] p-[16px] card mb-[16px] text-center">
                <h3 className="font-[600] text-[18px] md:text-[20px] text-white rounded">
                  NOMINATION SCREENING
                </h3>
              </div>
              <img src={arrow} alt="arrow" className="hidden md:block" />
              <div className="md:hidden flex justify-center">
                <MdArrowDownward
                  size={60}
                  className="text-center md:hidden text-primary"
                />
              </div>
              <div className="w-6/7 mx-auto md:mx-0 md:w-[365px] h-[64px] p-[16px] card mb-[16px] text-center rounded">
                <h3 className="font-[600] text-[18px] md:text-[20px] text-white ">
                  VOTING PROCESS
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-[32px] md:pb-[101px] px-[20px] md:pl-[80px] md:pr-[339px]">
          <p className="font-[600] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[16px]">
            Nomination Process
          </p>
          <p className="font-[400] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[20px] md:mb-[39px]">
            This is the beginning of the process where members of the public
            submit entries for their preferred candidate. To submit an entry,{" "}
            <Link to="/nomination-portal" className="text-primary">
              click here
            </Link>
            . To verify the criteria for selecting your preferred candidate,
            kindly{" "}
            <Link to="/criteria" className="text-primary">
              click here
            </Link>
            .
          </p>
          <p className="font-[600] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[16px]">
            Nomination Screening
          </p>
          <p className="font-[400] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[20px] md:mb-[39px]">
            It is important to note that after nominations are concluded, ADCA
            will begin the Vetting Process. Only entries that meet our criteria
            will move to the next stage. Those that do not meet our criteria
            will, however, be disqualified. At this stage, all successful
            nominations will be published for voting to commence.
          </p>
          <p className="font-[600] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[16px]">
            Voting Process
          </p>
          <p className="font-[400] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[20px] md:mb-[39px]">
            The voting process is into two categories. The first category allows
            the members of the public to vote. The second gives room to the ADCA
            committee to judge.{" "}
          </p>
          <p className="font-[400] text-[18px] md:text-[24px] mt-[32px] leading-8 mb-[20px] md:mb-[39px]">
            With ADCA, your votes count! The award portal is open for members of
            the general public to vote for their preferred nominees daily. Every
            voter has the privilege to vote for their preferred candidate once
            per day. To vote, kindly{" "}
            <Link to="/voting-portal-categories" className="text-primary">
              click here
            </Link>{" "}
          </p>
        </div>
      </Animation>
      <Footer />
    </div>
  );
};

export default HowWeJudge;
