import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Pages/LandingPage/Home';
import Adca from './Pages/LandingPage/Adca';
import Organizer from './Pages/LandingPage/Organizer';
import AdvisoryBoard from './Pages/LandingPage/AdvisoryBoard';
import Categories from './Pages/Categories';
import Entry from './Pages/LandingPage/Entry';
import HowToEnter from './Pages/LandingPage/HowToEnter';
import AwardCategories from './Pages/LandingPage/AwardCategories';
import Contact from './Pages/LandingPage/Contact';
import Sponsorship from './Pages/LandingPage/Sponsorship';
import Criteria from './Pages/LandingPage/Criteria';
import Rules from './Pages/LandingPage/Rules';
import HowWeJudge from './Pages/LandingPage/HowWeJudge';
import NominationPortal from './Pages/NominationPortal';
import VotingPortalCategories from './Pages/VotingPortalCategories';
import VotingPortal from './Pages/VotingPortal';
import ReactGA from 'react-ga';
import Reservation from './Pages/LandingPage/Reservation';
import Conference from './Pages/LandingPage/Conference';
import GalaNight from './Pages/LandingPage/GalaNight';
import BothEvent from './Pages/LandingPage/BothEvent';
import Nominees from './Pages/LandingPage/Nominees';
import EventSpeakers from './Pages/LandingPage/EventSpeakers';
import AdcaRegistration from './Pages/LandingPage/AdcaRegistration';
import About from './Pages/LandingPage/About';
import Adca2023 from './Pages/LandingPage/Adca2023';
import Speakers2023 from './Pages/LandingPage/Speakers2023';
import AdcaSchedule from './Pages/LandingPage/AdcaSchedule';
import PictureGallery from './Pages/LandingPage/PictureGalery';
import Adca2024 from './Pages/LandingPage/Adca2024';

// const url = "http://localhost:5000/verifyToken?token=";
function App() {
	const setGA = () => {
		ReactGA.initialize('G-BWG9F14Y0Z');
		ReactGA.pageview('New page view');
	};
	useEffect(() => {
		setGA();
	}, []);
	return (
		<>
			<Routes>
				<Route exact path='/' element={<Home />}></Route>
				{/* <Route exact path='/adca' element={<Adca />}></Route> */}
				<Route exact path='/adca' element={<About />}></Route>
				<Route exact path='/adca-2023' element={<Adca2023 />}></Route>
				<Route exact path='/adca-2024' element={<Adca2024 />}></Route>
				<Route
					exact
					path='/galery'
					element={<PictureGallery />}
				></Route>
				<Route
					exact
					path='/event-speakers'
					element={<EventSpeakers />}
				></Route>
				<Route
					exact
					path='/event-speakers-2023'
					element={<Speakers2023 />}
				></Route>
				<Route
					exact
					path='/adca-schedule'
					element={<AdcaSchedule />}
				></Route>
				<Route exact path='/organizer' element={<Organizer />}></Route>
				<Route
					exact
					path='/award-categories'
					element={<AwardCategories />}
				></Route>
				<Route
					exact
					path='/advice-board'
					element={<AdvisoryBoard />}
				></Route>
				<Route exact path='/entry' element={<Entry />}></Route>
				<Route
					exact
					path='/how-to-enter'
					element={<HowToEnter />}
				></Route>
				<Route exact path='/contact' element={<Contact />}></Route>
				<Route
					exact
					path='/reservation'
					element={<Reservation />}
				></Route>
				<Route
					exact
					path='/conference'
					element={<Conference />}
				></Route>
				<Route exact path='/gala-night' element={<GalaNight />}></Route>
				<Route exact path='/both-event' element={<BothEvent />}></Route>
				<Route
					exact
					path='/sponsorship'
					element={<Sponsorship />}
				></Route>
				<Route exact path='/criteria' element={<Criteria />}></Route>
				<Route exact path='/rules' element={<Rules />}></Route>
				<Route exact path='/nominees' element={<Nominees />}></Route>
				<Route
					exact
					path='/how-we-judge'
					element={<HowWeJudge />}
				></Route>
				<Route
					exact
					path='/register'
					element={<AdcaRegistration />}
				></Route>
				{/* <Route
            exact
            path="/nomination-portal"
            element={<NominationPortal />}
          ></Route> */}

				<Route
					exact
					path='/nomination-portal'
					element={<Home />}
				></Route>
				{/* change this to allow nomination */}
				<Route
					exact
					path='/voting-portal-categories'
					element={<Home />}
				></Route>
				<Route
					exact
					path='/voting-portal/:id'
					element={<Home />}
				></Route>
				{/* <Route
            exact
            path="/voting-portal-categories"
            element={<VotingPortalCategories />}
          ></Route>
          <Route
            exact
            path="/voting-portal/:id"
            element={<VotingPortal />}
          ></Route> */}
			</Routes>
		</>
	);
}

export default App;
