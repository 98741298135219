import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/imgs/logo.png";

const Footer = () => {
  return (
    <div className="bg-[#100F0F]">
      <div className="md:h-[365px] md:pr-[90px] py-[25px] md:pb-[0px] md:pt-[91px] md:flex ">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            className="h-[60px] md:h-auto mx-auto mb-[15px] md:mb-0 md:mr-[199px] md:ml-[108px]"
          />
        </Link>
        <div className="flex flex-col md:flex-row items-center md:items-start">
          <div className="mt-[25px] md:mr-[48px]">
            <Link
              to="/sponsorship"
              className="font-[600] text-[18px] md:text-[24px] text-primary hover:text-white"
            >
              SPONSORSHIP
            </Link>
            <div className="w-[64px] h-[2px] mt-[3px] bg-primary"></div>
          </div>
          <div className="mt-[25px] md:mr-[48px]">
            <Link
              to="/reservation"
              className="font-[600] text-[18px] md:text-[24px] text-primary hover:text-white"
            >
              RESERVATION
            </Link>
            <div className="w-[64px] h-[2px] mt-[3px] bg-primary"></div>
          </div>
          <div className="mt-[25px]">
            <Link
              to="/contact"
              className="font-[600] text-[18px] md:text-[24px] text-primary hover:text-white"
            >
              CONTACT US
            </Link>
            <div className="w-[64px] h-[2px] mt-[3px] bg-primary"></div>
          </div>
        </div>
      </div>
      <div className="text-center pb-[94px] mt-[15px]">
        <hr />
        <p className="font-[400px] text-[16px] leading-[24px] text-white mt-[16px]">
          Copyright ©{new Date().getFullYear()} All rights reserved | African
          Digital Conference and Awards.
        </p>
        <p className="font-[400px] text-[16px] leading-[24px] text-white mt-[16px]">
          Phone Number: <span>090770509331, 07018245477</span>{" "}
        </p>
        <p className="font-[400px] text-[16px] leading-[24px] text-white mt-[16px]">
          Email: <span>info@adcaward.com</span>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
