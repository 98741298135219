import React from "react";
import Footer from "../../Components/Footer";
import Nav from "../../Components/Nav";
import frame from "../../assets/imgs/award.png";
import Animation from "../../Components/Animation";
import { Link } from "react-router-dom";

const HowToEnter = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Nav />
      <Animation>
        <div className="pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]">
          <h1 className="md:max-w-[1164px] font-[600] text-[25px] md:text-[40px]">
            How to Enter
          </h1>
          <div className="w-[64px] h-[5px] mt-[8px] bg-primary"></div>
          <div className="block md:flex">
            <article className="w-4/5 mx-auto md:mx-0 md:w-[817px] md:mr-[66px]">
              <p
                className="font-[400] text-[18px] md:text-[24px] text-left mt-[32px]"
                style={{ lineHeight: "32px" }}
              >
                To submit an entry to participate in the Africa Digital
                Conference & Awards, kindly visit the
                <Link to="/award-categories" className="text-primary">
                  Nomination Category
                </Link>
              </p>
            </article>
            <span className="">
              <img src={frame} alt="about" />
            </span>
          </div>
        </div>
      </Animation>
      <Footer />
    </div>
  );
};

export default HowToEnter;
