import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { url } from '../url';

const Milestone = () => {
	const [counterOn, setCounterOn] = useState(false);
	const [nominees, setNominees] = useState(0);
	const [candidates, setCandidates] = useState(0);

	const fetchNominees = async () => {
		const response = await fetch(`${url}/nominations`);
		const data = await response.json();
		setNominees(data.length);
		// console.log(data.length);
	};
	useEffect(() => {
		fetchNominees();
		return () => {
			fetchNominees();
		};
	}, []);
	const fetchCandidate = async () => {
		const response = await fetch(`${url}/vote/candidates`);
		const data = await response.json();
		setCandidates(data.length);
	};
	useEffect(() => {
		fetchCandidate();
		return () => {
			fetchCandidate();
		};
	}, []);
	return (
		<ScrollTrigger
			onEnter={() => setCounterOn(true)}
			onExit={() => setCounterOn(false)}
		>
			<div className='md:h-[297px] bg-black px-[40px] text-white pb-[20px] md:pb-[0px] pt-[64px]'>
				<h1 className='font-[600] text-[25px] md:text-[30px]'>
					ADCA MILESTONE
				</h1>
				<div className='w-[96px] h-[2px] bg-primary'></div>
				<div className='mt-[20px] md:mt-[40px] mx-[40px] block md:flex md:justify-between mb-[25px] md:mb-[40px]'>
					<div className='text-center mb-[35px]'>
						<p className='font-[600] text-[40px]'>
							{counterOn && (
								<CountUp
									start={0}
									end={1}
									duration={2}
									delay={0}
								/>
							)}
							<sup>st</sup>
						</p>
						<p className='mt-[0px] md:mt-[16px] font-[600] text-[18px] md:text-[24px]'>
							EDITION
						</p>
					</div>
					<div className='text-center mb-[35px]'>
						<p className='font-[600] text-[40px]'>
							{counterOn && (
								<CountUp
									start={0}
									end={nominees + candidates}
									duration={2}
									delay={0}
								/>
							)}
							+
						</p>
						<p className='mt-[0px] md:mt-[16px] font-[600] text-[18px] md:text-[24px]'>
							NOMINEES
						</p>
					</div>
					<div className='text-center mb-[35px]'>
						<p className='font-[600] text-[40px]'>
							{counterOn && (
								<CountUp
									start={0}
									end={0}
									duration={2}
									delay={0}
								/>
							)}
							+
						</p>
						<p className='mt-[0px] md:mt-[16px] font-[600] text-[18px] md:text-[24px]'>
							WINNERS
						</p>
					</div>
					<div className='text-center mb-[35px]'>
						<p className='font-[600] text-[40px]'>
							{counterOn && (
								<CountUp
									start={0}
									end={500}
									duration={2}
									delay={0}
								/>
							)}
							+
						</p>
						<p className='mt-[0px] md:mt-[16px] font-[600] text-[18px] md:text-[24px]'>
							ATTENDEES
						</p>
					</div>
				</div>
			</div>
		</ScrollTrigger>
	);
};

export default Milestone;
