import React, { useState, useRef } from "react";
import Footer from "../../Components/Footer";
import Nav from "../../Components/Nav";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "animate.css";
import Animation from "../../Components/Animation";
// import emailjs from 'emailjs-com'
const isValidEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

const Sponsorship = () => {
  const [fname, setFName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const form = useRef();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sendEmail = (e) => {
    e.preventDefault();
    // if (fname && lname && email && number && message) {
    //   const user_name = `${fname} ${lname}`
    //   setEmail(isValidEmail(email))

    //   });

    //     setFName('');
    //     setEmail('');
    //     setMessage('');
    //     setEmailSent(true);
    // } else {
    //     alert('Please fill in all fields.');
    // }
  };
  const MySwal = withReactContent(Swal);
  const handleAlert = () => {
    Swal.fire({
      title: "THANK YOU!",
      text: "We appreciate your efforts in reaching out to us. We will reply to your mail shortly",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });

    //     MySwal.fire({
    //       title: <p className='py-[30px]'>Processing your request</p>,
    //       didOpen: () => {
    //         // `MySwal` is a subclass of `Swal` with all the same instance & static methods
    //         MySwal.showLoading()
    //       },
    //     }).then(() => {
    //       return MySwal.fire(<h1>THANK YOU!
    // </h1>,<p>We appreciate your efforts in reaching out to us. We will reply to your mail shortly.</p>)
    //     })
  };
  return (
    <>
      <Nav />
      <Animation>
        <div className="bg-[#f8f6f5] pt-[64px] pb-[40px]">
          <article className="md:pl-[160px] pl-[20px] w-[4/5] mb-[32px] mx-auto md:mx-0 md:w-[1117px]">
            <h1 className="font-[600] text-[20px] md:text-[30px]">Sponsorship</h1>
            <div className="w-[96px] h-[5px] mb-[32px] md:ml-0 bg-primary"></div>

            <p className="leading-8 font-[400] text-[18px] md:text-[24px] text-left mt-[32px]">
              The African Digital Conference and Awards especially recognize,
              celebrate and reward Tech experts, Innovators, Entrepreneurs,
              Academicians, Policymakers, Organizations, and Key Stakeholders in
              the tech industry across Africa.
            </p>
            <p className="leading-8 font-[400] text-[18px] md:text-[24px] text-left mt-[32px]">
              Alone, we cannot achieve our noble cause but with your support, we
              would be able to seamlessly make the necessary impact.{" "}
            </p>
          </article>
          <aside className="w-5/6 mx-auto md:w-[720px] bg-white py-[40px]">
            {/* <div className="md:max-w-[553px] mx-auto w-full text-center pt-[56px]"> */}
            <p className="w-4/5 md:max-w-[500px] mx-auto text-center text-[18px] md:text-[24px] font-[400] leading-8 ">
              Simply fill the form below
            </p>
            {/* </div> */}
            <div className="form mt-[32px] px-[20px] md:px-[80px]">
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-inp">
                  <label
                    htmlFor="fname"
                    className="font-[400] text-[18px] md:text-[24px]"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fname"
                    className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                    }}
                    name="user_first_name"
                    placeholder="Kelechi"
                    value={fname}
                    onChange={(e) => setFName(e.target.value)}
                  />
                </div>
                <div className="form-inp mt-[24px]">
                  <label
                    htmlFor="email"
                    className="font-[400] text-[18px] md:text-[24px]"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                    }}
                    placeholder="femmyte@gmail.com"
                    value={email}
                    name="user_email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-inp mt-[24px]">
                  <label
                    htmlFor="number"
                    className="font-[400] text-[18px] md:text-[24px]"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="number"
                    className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                    }}
                    placeholder="08101234567"
                    name="user_number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="form-inp mt-[24px]">
                  <label
                    htmlFor="country"
                    className="font-[400] text-[18px] md:text-[24px]"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                    }}
                    placeholder="Femmyte"
                    name="user_last_name"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="form-inp mt-[24px]">
                  <label
                    htmlFor="state"
                    className="font-[400] text-[18px] md:text-[24px]"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    id="state"
                    className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                    }}
                    placeholder="Femmyte"
                    name="user_last_name"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
                <div className="form-inp mt-[24px]">
                  <textarea
                    name="message"
                    id="message"
                    className="w-full mt-[16px] h-[150px] md:h-[267px] pl-[24px] pt-[32px]"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                    }}
                    placeholder="Your Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="h-[64px] font-[600] text-[18px] md:text-[24px] text-black w-full bg-primary hover:bg-primary mt-[36px] rounded-full"
                  onClick={handleAlert}
                >
                  Submit your message
                </button>
                <span className={emailSent ? "block" : "hidden"}>
                  Thank you for your message, we will be in touch in no time!
                </span>
              </form>
            </div>
          </aside>
        </div>
      </Animation>
      <Footer />
    </>
  );
};

export default Sponsorship;
