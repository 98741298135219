import React from 'react';
import Animation from '../../Components/Animation';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Partners from '../../Components/Partners';
import Schedule from '../../Components/Schedule';
import frame from '../../assets/imgs/award.png';
import image from '../../assets/imgs/award/five.JPG';
import { Link } from 'react-router-dom';

const Adca2024 = ({ title, text1, enter }) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='overflow-hidden w-screen'>
			<Nav />
			<div className='relative h-screen'>
				<div
					className={`w-full h-full absolute transform transition-transform `}
				>
					<img
						src={image}
						alt=''
						className='w-full h-full object-cover object-center'
					/>
					<div className='absolute inset-0 flex items-center justify-center text-white text-center bg-opacity-70 bg-black'>
						<div>
							<h1 className='font-[600] text-[27px] md:text-[35px] text-white leading-[46px] md:w-[70%] text-center mx-auto'>
								Welcome to the African Digital Conference and
								Awards 2024
							</h1>
							<h3 className='font-[600] text-[20px] my-5 md:w-[70%] text-center mx-auto mb-[30px]'>
								<span className='font-[900]'>Theme:</span>{' '}
								Digital renaissance: Harnessing Blockchain
								technology in creative and art industry for
								growth and wealth creation in Africa
							</h3>
							<Link to='/reservation'>
								<button className='md:min-w-[400px] md:h-[56px] py-[12px] px-[13px] md:px-[16px] bg-primary font-[600] text-[16px] md:text-[22px] rounded-full'>
									RESERVE A SEAT
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:px-[30px]'>
					{/* <div className='block md:flex'> */}
					<article className=' mb-[30px] md:mb-0 w-full'>
						<p
							className='font-[400] text-[18px] md:text-[20px] text-left mt-[32px]'
							style={{ lineHeight: '32px' }}
						>
							The ADDCA 2024 tagged "Digital Renaissance" is a
							groundbreaking event at the intersection of
							creativity, technology, and cultural expression in
							Africa. This two-day conference and gala night is a
							celebration of the profound impact that blockchain
							technology and Non-Fungible Tokens (NFTs) are having
							on the creative, art, fashion, and cultural sectors
							across the African continent.
						</p>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								What to Expect:
							</h1>
							<ul className='ml-10'>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Inspiration:
									</span>
									Immerse yourself in a world of innovation,
									where technology and art converge. Be
									inspired by visionaries, artists, designers,
									and cultural preservationists who are using
									digital tools to redefine their crafts.
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Education:
									</span>
									Gain insights into the transformative power
									of blockchain and NFTs in preserving
									cultural heritage, revolutionizing art
									markets, and creating new opportunities for
									artists, designers, and creators.
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Networking:
									</span>
									Connect with like-minded professionals,
									creative minds, and industry leaders. Forge
									collaborations, exchange ideas, and build
									partnerships that can shape the future of
									creativity in Africa.
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Recognition:
									</span>{' '}
									Be part of the Gala and Award Night, where
									we honor those who have made extraordinary
									contributions to the creative and cultural
									sectors. It's a night of glamour, cultural
									performances, and celebrating the
									trailblazers in our industries.
								</li>
							</ul>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Key Highlights:
							</h1>
							<ul className='ml-10'>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Inspirational Keynotes:
									</span>
									Hear from renowned thought leaders and
									pioneers who are shaping the digital
									technology, creative, art, fashion, and
									cultural landscape in Africa.
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Panel Discussions:
									</span>
									Engage in discussions on NFTs, digital art,
									fashion, cultural preservation, and the
									future of creative industries.
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Workshops:
									</span>
									Participate in hands-on workshops, including
									the creation of NFTs and digital marketing
									strategies tailored to your creative
									ventures.
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Startup Showcase:
									</span>
									Discover the innovative startups driving
									change in the creative and cultural space
									through blockchain technology.
								</li>
							</ul>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Who Should Attend:
							</h1>
							<ul className='ml-10'>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Artists and Creators
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Fashion Designers
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									Cultural Preservationists
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									Digital Technologists
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Digital Marketing Professionals
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Entrepreneurs
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Investors
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Students and Scholars
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Anyone passionate about the intersection of
									creativity and technology in Africa
								</li>
							</ul>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Event Details:
							</h1>
							<div className='bg-white shadow-md rounded my-6 mx-w-full '>
								<table className=' w-full overflow-x-auto table-auto'>
									<tr className='bg-gray-100 text-gray-800 font-[800] text-sm leading-normal'>
										<th className='py-3 px-6 text-left'>
											Date
										</th>
										<th className='py-3 px-6 text-left'>
											Time
										</th>
										<th className='py-3 px-6 text-left'>
											Venue
										</th>
									</tr>
									<tr className='bg-gray-200 text-gray-600 text-sm leading-normal'>
										<td className='py-3 px-6 text-left'>
											11 - 12 April
										</td>
										<td className='py-3 px-6 text-left'>
											8:00 AM - 5:00 PM
										</td>
										<td className='py-3 px-6 text-left'>
											Crespan Hotel, Jos
										</td>
									</tr>
								</table>
							</div>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								PROGRAM SCHEDULE
							</h1>
							<div className='bg-white shadow-md rounded my-6 mx-w-full '>
								<table className=' w-full overflow-x-auto table-auto'>
									<thead>
										<tr className='bg-gray-200 text-gray-600 text-sm leading-normal'>
											<th className='py-3 px-6 text-left'>
												Day
											</th>
											<th className='py-3 px-6 text-left'>
												Time
											</th>
											<th className='py-3 px-6 text-left'>
												Event
											</th>
										</tr>
									</thead>
									<tbody className='text-gray-600 text-sm font-light'>
										{/* Day 1 */}
										<tr className='border-b border-gray-200 hover:bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												Day 1: Conference
											</td>
											<td className='py-3 px-6 text-left'>
												Morning
											</td>
											<td className='py-3 px-6 text-left'>
												<ul className='list-disc ml-4'>
													<li>
														8:00 AM - 9:00 AM:
														Registration
													</li>
													<li>
														9:00 AM - 9:45 AM:
														Opening Ceremony &
														Welcome Remarks
													</li>
													<li>
														9:45 AM - 10:10 AM:
														Opening Keynote -
														"Digital Renaissance and
														its Impact on African
														Creativity"
													</li>
													<li>
														10:10 AM - 10:35 AM:
														Goodwill Messages
													</li>
													<li>
														10:35 AM - 11:00 AM: Tea
														break and networking
													</li>
													<li>
														11:00 AM - 11:25 AM:
														Panel Discussion - "NFTs
														and the Creative
														Industry in Africa"
													</li>
													<li>
														11:25 AM - 11:55 AM:
														Keynote - "Smart
														Contracts for Artistic
														Royalties:"
													</li>
													<li>
														11:55 AM - 12:25 PM:
														Fireside chat - "NFTs in
														fashion: a chat with a
														fashion designer who has
														ventured into the world
														of NFTs, exploring the
														synergy between fashion
														and blockchain."
													</li>
													<li>
														12:25 PM - 1:00 PM:
														Exhibition booth tour
													</li>
													<li>
														1:00 PM - 2:00 PM:
														Networking Lunch
													</li>
												</ul>
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover:bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												Afternoon (workshop/breakout
												session)
											</td>
											<td className='py-3 px-6 text-left'>
												2:00 PM - 3:30 PM
											</td>
											<td className='py-3 px-6 text-left'>
												<ul className='list-disc ml-4'>
													<li>
														Workshop 'A': Legal and
														copyright considerations
														for NFTs: Understanding
														intellectual property
														rights, royalties, and
														legal aspects when
														dealing with NFTs in
														art, fashion, and music.
													</li>
													<li>
														Workshop 'B': Unlocking
														film funding with NFTs:
														Discussing how
														filmmakers and studios
														can leverage NFTs to
														fund their projects and
														engage with a global
														community of investors.
													</li>
													<li>
														Workshop 'C': Truffle:
														Navigating the Ethereum
														DApp Ecosystem
													</li>
													<li>
														3:30 PM - 4:00 PM:
														Fireside chat: fireside
														chat: Crafting Ethereum
														Solutions with Ease
													</li>
													<li>
														4:00 PM - 4:30 PM:
														Parliament session/Q&A
														and Wrap-up.
													</li>
												</ul>
											</td>
										</tr>

										{/* Day 2 */}
										<tr className='border-b border-gray-200 hover:bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												Day 2: Conference & Gala/Award
												Night
											</td>
											<td className='py-3 px-6 text-left'>
												Mornings
											</td>
											<td className='py-3 px-6 text-left'>
												<ul className='list-disc ml-4'>
													<li>
														8:30 AM - 9:00 AM: Recap
														of Day 1
													</li>
													<li>
														9:00 AM - 9:30 AM: Panel
														Discussion - "Ganache:
														Your Personal Blockchain
														Playground"
													</li>
													<li>
														9:30 AM - 10:00 AM:
														Goodwill messages
													</li>
													<li>
														10:00 AM - 10:30 AM: Tea
														break and networking
													</li>
													<li>
														10:30 AM - 11:00 AM:
														Keynote - Tokenizing
														Fashion Collectibles:
													</li>
													<li>
														11:00 AM - 12:30 PM:
														Startup showcase
													</li>
													<li>
														12:30 PM - 1:30 PM:
														Networking Lunch
													</li>
													{/* <li>
														1:30 PM - 3:00 PM:
														workshops and break out
													</li> */}
												</ul>
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover:bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												Afternoon (workshop/breakout
												session)
											</td>
											<td className='py-3 px-6 text-left'>
												Workshops and Breakout Sessions
											</td>
											<td className='py-3 px-6 text-left'>
												<ul className='list-disc ml-4'>
													<li>
														Workshop 'A': Remix IDE:
														Unlocking the Potential
													</li>
													<li>
														Workshop 'B': Truffle:
														Navigating the Ethereum
														DApp Ecosystem
													</li>
													<li>
														3:00 PM - 3:30 PM:
														Closing Remarks,
														Acknowledgments, and
														Award Preview
													</li>
												</ul>
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover:bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												Evening - (Gala and Award Night)
											</td>
											<td className='py-3 px-6 text-left'>
												Gala and Award Night
											</td>
											<td className='py-3 px-6 text-left'>
												<ul className='list-disc ml-4'>
													<li>
														6:00 PM - 7:00 PM: Red
														Carpet and Cocktail Hour
													</li>
													<li>
														7:00 PM - 8:30 PM: Gala
														Dinner
													</li>
													<li>
														8:30 PM - 10:30 PM:
														Awards Ceremony -
														Recognizing Outstanding
														Contributors to the
														Creative, Art, Fashion,
														Cultural Industries
													</li>
													<li>
														10:30 PM - 11:00 PM:
														Entertainment and
														Cultural Performances
													</li>
													<li>
														11:00 PM: Closing
														Remarks and Celebration
													</li>
												</ul>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						{/* <div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								PROGRAM SCHEDULE:
							</h1>
							<ul className='ml-10'>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Inspiration:
									</span>
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Inspiration:
									</span>
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Inspiration:
									</span>
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									<span className='font-[700] mr-2'>
										Inspiration:
									</span>
								</li>
							</ul>
						</div> */}
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Why Attend:
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								The ADDCA 2024 is your gateway to a world where
								creativity knows no bounds. Explore the
								possibilities that blockchain and NFTs offer to
								artists, designers, cultural enthusiasts, and
								entrepreneurs. Whether you are an established
								professional or a budding talent, this event
								will empower you with knowledge, connections,
								and inspiration to thrive in the ever-evolving
								creative landscape.
							</p>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Registration:
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								Don't miss this opportunity to be a part of the
								Digital Renaissance. Secure your spot today.
								Early bird discounts are available, so register
								now to take advantage.
							</p>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Sponsorships:
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								Become a sponsor of the "Digital Renaissance"
								conference and position your brand at the
								forefront of the digital transformation of
								Africa's creative industries. Reach out to us to
								explore sponsorship opportunities
							</p>
						</div>
					</article>
				</div>
			</Animation>
			<Animation>
				<Schedule />
			</Animation>
			<Animation>
				<Partners second />
			</Animation>
			<Animation>
				<Footer />
			</Animation>
		</div>
	);
};

export default Adca2024;
