import React from "react";
import frame from "../assets/imgs/award.png";
const About = ({ title, text1, text2, text3, text4, text5, enter }) => {
  return (
    <div className="pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]">
      <h1 className="md:max-w-[1164px] font-[600] text-[20px] md:text-[40px] text-center md:text-left">
        {title}
      </h1>
      <div className="mx-auto md:mx-0 w-[64px] h-[2px] mt-[3px] bg-primary"></div>
      <div className="block md:flex">
        <article className="w-5/6 mx-auto md:mx-0 md:w-[817px] md:mr-[66px] mb-[30px] md:mb-0">
          <p
            className="font-[400] text-[18px] md:text-[24px] text-left mt-[32px]"
            style={{ lineHeight: "32px" }}
          >
            {text1}
          </p>
          {text2 && (
            <p
              className="mt-[24px] font-[400] text-[18px] md:text-[24px] text-left"
              style={{ lineHeight: "32px" }}
            >
              {text2}
            </p>
          )}
          {text3 && (
            <p
              className="mt-[24px] font-[400] text-[18px] md:text-[24px] text-left"
              style={{ lineHeight: "32px" }}
            >
              {text3}
            </p>
          )}
          {text4 && (
            <p
              className="mt-[24px] font-[400] text-[18px] md:text-[24px] text-left"
              style={{ lineHeight: "32px" }}
            >
              {text4}
            </p>
          )}
          {text5 && (
            <p
              className="mt-[24px] font-[400] text-[18px] md:text-[24px] text-left"
              style={{ lineHeight: "32px" }}
            >
              {text5}
            </p>
          )}
        </article>
        <span className={`${enter ? "mt-0" : "mt-[168px]"}`}>
          <img src={frame} alt="about" />
        </span>
      </div>
    </div>
  );
};

export default About;
