import React from 'react';
// import galery1 from '../assets/imgs/galery1.png';
// import galery2 from '../assets/imgs/galery2.png';
// import galery3 from '../assets/imgs/galery3.png';
import one from '../assets/imgs/award/award9.JPG';
// import two from '../assets/imgs/award/two.JPG';
// import three from '../assets/imgs/award/three.JPG';
// import four from '../assets/imgs/award/four.JPG';
// import five from '../assets/imgs/award/five.JPG';
// import six from '../assets/imgs/award/six.JPG';
// import seven from '../assets/imgs/award/seven.JPG';
// import eight from '../assets/imgs/award/eight.JPG';
// import nine from '../assets/imgs/award/nine.JPG';
// import ten from '../assets/imgs/award/ten.JPG';
// import aw from '../assets/imgs/award/aw.JPG';
import award1 from '../assets/imgs/award/award1.JPG';
import award2 from '../assets/imgs/award/award2.JPG';
import award4 from '../assets/imgs/award/award4.JPG';
import award5 from '../assets/imgs/award/award5.JPG';
import award6 from '../assets/imgs/award/award6.JPG';
// import { LazyLoadImage } from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: Import a CSS effect for placeholder transition (e.g., blur)
import { Link } from 'react-router-dom';

const images = [one, award1, award2, award4, award5, award6];
const Gallery = () => {
	return (
		<div className=' pt-[64px] px-[20px] mx-auto md:px-[80px]'>
			<h1 className='font-[600] text-[20px] md:text-[30px] '>
				ADCA GALLERY
			</h1>
			<div className='w-[85px] h-[2px] mt-[3px] bg-primary'></div>
			<div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-[32px]'>
				{images.map((image) => {
					return (
						<LazyLoadImage
							src={image}
							alt='Image Description'
							effect='blur'
							placeholderSrc={one}
						/>
					);
				})}
			</div>
			<div className='flex justify-center my-4'>
				<Link to='/galery'>
					<button className='px-5 py-2 mt-6 bg-primary font-[600] text-[16px] md:text-[22px] rounded-full'>
						View More
					</button>
				</Link>
			</div>
		</div>
	);
};

export default Gallery;
