import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Nav from "../../Components/Nav";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "animate.css";
import { url } from "../../url";
import Animation from "../../Components/Animation";
import Modal from "../../Components/Modal";

const Conference = () => {
  const [state, setState] = useState([
    {
      name: "",
      address: "",
      country: "",
      state: "",
      city: "",
      number: "",
      gender: "",
      organization: "",
      position: "",
    },
  ]);
  const MySwal = withReactContent(Swal);

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // =====  Uncomment this to allow nomination

    let createNominees = async () => {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let response = await fetch(`${url}nominations`, {
        method: "post",
        headers: myHeaders,
        body: JSON.stringify({
          name: state.name,
          address: state.address,
        }),
      });
    };
  };
  return (
    <>
      <Nav />
      <Animation>
        <div className="p-[20px] md:p-[60px] md:px-[80px]">
          <h1 className="md:max-w-[1164px] font-[600] text-[20px] md:text-[40px] uppercase">
            reservation
          </h1>
          <div className="w-[96px] h-[5px] mt-[8px] mb-[32px] bg-primary"></div>
          <h3 className="mb-[32px] font-[600] text-[24px] leading-6">
            THE AFRICAN DIGITAL CONFERENCE
          </h3>
          <h3 className="mb-[8px] font-[600] text-[18px] leading-6">
            PERSONAL INFO
          </h3>
          <p className="font-[400] text-[16px] leading-6">
            KINDLY INPUT YOUR PERSONAL INFO
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-inp mt-[24px] md:w-full">
              <label
                htmlFor="name"
                className="font-[400] text-[20px]  md:text-[24px]"
              >
                Full Name <span className="text-red-500"> *</span>
              </label>
              <input
                required
                type="text"
                id="name"
                className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                style={{
                  background: "#FFFFFF",
                  boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                  borderRadius: "8px",
                }}
                placeholder="full name"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
            </div>
            <div className="md:flex md:gap-[122px]">
              <div className="form-inp mt-[24px] md:w-[616px]">
                <label
                  htmlFor="email"
                  className="font-[400] text-[20px]  md:text-[24px]"
                >
                  Email Address <span className="text-red-500"> *</span>
                </label>
                <input
                  required
                  type="text"
                  id="email"
                  className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                  style={{
                    background: "#FFFFFF",
                    boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "8px",
                  }}
                  placeholder="email"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-inp mt-[24px] md:w-[616px]">
                <label
                  htmlFor="country"
                  className="font-[400] text-[20px]  md:text-[24px]"
                >
                  Country <span className="text-red-500"> *</span>
                </label>
                <input
                  required
                  type="tel"
                  id="country"
                  className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                  style={{
                    background: "#FFFFFF",
                    boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "8px",
                  }}
                  placeholder="Country"
                  name="country"
                  value={state.country}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* END OF FORM ROW */}
            <div className="md:flex md:gap-[122px]">
              <div className="form-inp mt-[24px] md:w-[616px]">
                <label
                  htmlFor="state"
                  className="font-[400] text-[20px]  md:text-[24px]"
                >
                  State <span className="text-red-500"> *</span>
                </label>
                <input
                  required
                  type="text"
                  id="state"
                  className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                  style={{
                    background: "#FFFFFF",
                    boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "8px",
                  }}
                  placeholder="Plateau"
                  name="state"
                  value={state.state}
                  onChange={handleChange}
                />
              </div>
              <div className="form-inp mt-[24px] md:w-[616px]">
                <label
                  htmlFor="city"
                  className="font-[400] text-[20px]  md:text-[24px]"
                >
                  city <span className="text-red-500"> *</span>
                </label>
                <input
                  required
                  type="text"
                  id="city"
                  className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                  style={{
                    background: "#FFFFFF",
                    boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "8px",
                  }}
                  placeholder="Jos"
                  name="city"
                  value={state.city}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* END OF FORM ROW */}
            <div className="md:flex md:gap-[122px]">
              <div className="form-inp mt-[24px] md:w-[616px]">
                <label
                  htmlFor="number"
                  className="font-[400] text-[20px]  md:text-[24px]"
                >
                  Mobile Phone No <span className="text-red-500"> *</span>
                </label>
                <input
                  required
                  type="text"
                  id="number"
                  className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                  style={{
                    background: "#FFFFFF",
                    boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "8px",
                  }}
                  placeholder="+2348012345567"
                  name="number"
                  value={state.number}
                  onChange={handleChange}
                />
              </div>
              <div className="form-inp mt-[24px] md:w-[616px]">
                <p className="font-[400] text-[20px]  md:text-[24px] mb-3">
                  Gender <span className="text-red-500"> *</span>
                </p>
                <div className="mb-[10px]">
                  <input
                    required
                    type="radio"
                    name="gender"
                    id="Male"
                    value="Male"
                    checked={state.gender === "Male"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Male"
                    className="ml-[12px] font-[400] text-[20px] leading-6"
                  >
                    Male
                  </label>
                </div>
                <div>
                  <input
                    required
                    type="radio"
                    name="gender"
                    id="Female"
                    value="Female"
                    checked={state.gender === "Female"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Female"
                    className="ml-[12px] font-[400] text-[20px] leading-6"
                  >
                    Female
                  </label>
                </div>
              </div>
            </div>
            <h3 className="mt-[48px] mb-[8px] font-[600] text-[20px] md:text-[24px] leading-6">
              Organizational Info.
            </h3>
            <p className="mb-[32px] font-[400] text-[16px] leading-6">
              If you are representing an organisation, kindly fill in this
              section.
            </p>
            {/* END OF FORM ROW */}
            <div className="md:flex md:gap-[122px]">
              <div className="form-inp mt-[24px] md:w-[616px]">
                <label
                  htmlFor="organization"
                  className="font-[400] text-[20px]  md:text-[24px]"
                >
                  Name of Organization
                </label>
                <input
                  type="text"
                  id="organization"
                  className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                  style={{
                    background: "#FFFFFF",
                    boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "8px",
                  }}
                  placeholder="Name of Organization"
                  name="organization"
                  value={state.organization}
                  onChange={handleChange}
                />
              </div>
              <div className="form-inp mt-[24px] md:w-[616px]">
                <label
                  htmlFor="postion"
                  className="font-[400] text-[20px]  md:text-[24px]"
                >
                  Position/Job Title
                </label>
                <input
                  type="text"
                  id="postion"
                  className="w-full h-[56px] border-none mt-[8px] p-[16px]"
                  style={{
                    background: "#FFFFFF",
                    boxShadow: " 4px 4px 8px rgba(0, 0, 0, 0.16)",
                    borderRadius: "8px",
                  }}
                  placeholder="C.E.O"
                  name="postion"
                  value={state.postion}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-[32px]">
              <div className="form-inp mt-[24px] md:w-[616px]">
                <p className="font-[600] text-[20px]  md:text-[24px] mb-3">
                  Which of the workshops would you like to attend?
                  <span className="text-red-500"> *</span>
                </p>

                <div className="mb-[10px]">
                  <input
                    required
                    type="radio"
                    name="gender"
                    id="Male"
                    value="Male"
                    checked={state.gender === "Male"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Male"
                    className="ml-[12px] font-[400] text-[20px] leading-6"
                  >
                    Male
                  </label>
                </div>
                <div>
                  <input
                    required
                    type="radio"
                    name="gender"
                    id="Female"
                    value="Female"
                    checked={state.gender === "Female"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Female"
                    className="ml-[12px] font-[400] text-[20px] leading-6"
                  >
                    Female
                  </label>
                </div>
              </div>
              <div className="form-inp mt-[24px] md:w-[616px]">
                <p className="font-[600] text-[20px]  md:text-[24px] mb-3">
                  How would you like to attend this event?
                  <span className="text-red-500"> *</span>{" "}
                </p>
                <div className="mb-[10px]">
                  <input
                    required
                    type="radio"
                    name="mode"
                    id="Physical"
                    value="Physical"
                    checked={state.mode === "Physical"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Physical"
                    className="ml-[12px] font-[400] text-[20px] leading-6"
                  >
                    Physical
                  </label>
                </div>
                <div>
                  <input
                    required
                    type="radio"
                    name="mode"
                    id="Virtual"
                    value="Virtual"
                    checked={state.mode === "Virtual"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Virtual"
                    className="ml-[12px] font-[400] text-[20px] leading-6"
                  >
                    Virtual
                  </label>
                </div>
              </div>
            </div>
            <div className="w-4/5 md:w-[332px] mx-auto md:mx-0">
              <button
                type="submit"
                className="h-[56px] font-[600] text-[20px] md:text-[24px] text-black w-full bg-primary hover:bg-primary mt-[36px] rounded-full"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Animation>
      <Footer />
    </>
  );
};

export default Conference;
