import React from "react";
import { Link } from "react-router-dom";

const ScheduleCard = ({ text, date, other, link, address }) => {
  return (
    <div className="">
      <div className="w-6/7 mx-auto md:mx-0 md:w-[357px] h-[64px] p-[16px] card mt-[48px] mb-[16px] text-center">
        <h3 className="font-[600] text-[18px] md:text-[24px] text-white rounded-sm">
          {text}
        </h3>
      </div>
      <div className="text-center w-full md:w-[357px] ">
        <p className=" font-[700] text-[16px]">{date}</p>
        <div className="w-[357px] my-[18px] border border-[#828181] "></div>
        <p className="font-[700] text-[16px]">
          {other},{" "}
          {link && (
            <Link to={`/${address}`} className="text-primary">
              click here.{" "}
            </Link>
          )}
        </p>
      </div>
    </div>
  );
};

export default ScheduleCard;
