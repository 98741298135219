import React, { useState, useEffect } from 'react';
import SpeakersCard from '../../Components/SpeakersCard';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Animation from '../../Components/Animation';
import { url } from '../../url';

const EventSpeakers = () => {
	const [speakers, setSpeakers] = useState([]);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetchSpeakers = async () => {
		const response = await fetch(`${url}speakers`);
		const data = await response.json();
		console.log(data);
		setSpeakers(data);
	};
	useEffect(() => {
		fetchSpeakers();
		return () => {
			fetchSpeakers();
		};
	}, []);
	return (
		<>
			<Nav />
			<Animation>
				<div className='p-[20px] md:p-[60px] md:px-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px] uppercase'>
						2023 ADCA Speakers
					</h1>
					<div className='w-[96px] h-[5px] mt-[8px] mb-[32px] bg-primary'></div>
					{/* <h3 className="mb-[32px] font-[600] text-[24px] leading-6">
            Which of the ADCA events would you like to attend?
          </h3> */}
					<div className='md:flex md:justify-evenly gap-2 md:flex-wrap w-4/5 mx-auto my-10'>
						{speakers &&
							speakers.map((speaker) => (
								<SpeakersCard
									img={speaker.profileUrl}
									name={speaker.name}
									bio={speaker.bio}
									location={speaker.country}
									linkedIn={speaker.socialLinks.linkedin}
									facebook={speaker.socialLinks.facebook}
									twitter={speaker.socialLinks.twitter}
								/>
							))}
					</div>
				</div>
			</Animation>
			<Footer />
		</>
	);
};

export default EventSpeakers;
