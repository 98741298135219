import React from 'react';
import Animation from '../../Components/Animation';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Partners from '../../Components/Partners';
import Schedule from '../../Components/Schedule';
import frame from '../../assets/imgs/award.png';
import image from '../../assets/imgs/award/ten.JPG';

import { Link } from 'react-router-dom';
const Box = ({ title, description, img }) => {
	return (
		// <Animation>
		<div className='overflow-hidden py-[30px] bg-white rounded-[35px] shadow-md shadow-[rgba(0, 0, 0, 0.14)] flex flex-col justify-center px-[20px] mb-[20px] md:mb-[0]'>
			{/* <img
				src={img}
				alt={title}
				className='w-[57px] h-[57px] md:mb-[30px]'
			/> */}
			<p className='font-space font-[600] text-[20px] leading-[25px] md:leading-[36px] text-black text-center'>
				{title}
			</p>
			<p className='mt-[20px] font-dmsans text-[16px] leading-[24px] text-[#202020]'>
				{description}{' '}
			</p>
		</div>
		// </Animation>
	);
};

const Adca2024 = ({ title, text1, enter }) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className='overflow-hidden'>
			<Nav />
			<div className='relative h-screen'>
				<div
					className={`w-full h-full absolute transform transition-transform `}
				>
					<img
						src={image}
						alt=''
						className='w-full h-full object-cover object-center'
					/>
					<div className='absolute inset-0 flex items-center justify-center text-white text-center bg-opacity-70 bg-black'>
						<div>
							<h1 className='font-[600] text-[27px] md:text-[35px] text-white leading-[46px] md:w-[70%] text-center mx-auto'>
								Welcome to the African Digital Conference and
								Awards 2023
							</h1>
							<h3 className='font-[600] text-[20px] my-5 md:w-[70%] text-center mx-auto mb-[30px]'>
								<span className='font-[900]'>Theme:</span>{' '}
								Bridging the Future - Gig Economy and Blockchain
								in Africa
							</h3>
							<Link to='/galery'>
								<button className='md:min-w-[400px] md:h-[56px] py-[12px] px-[13px] md:px-[16px] bg-primary font-[600] text-[16px] md:text-[22px] rounded-full'>
									VIEW EXCERPTS
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					{/* <div className='block md:flex'> */}
					<article className='w-5/6 mx-auto md:w-4/6 mb-[30px] md:mb-0'>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Introduction:
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								Welcome to the Africa Digital Conference and
								Award, a pioneering event that promises to
								illuminate the path to the future of work in
								Africa. With a focus on the dynamic intersection
								of the Gig Economy and Blockchain, we invite you
								to embark on a journey of exploration and
								innovation.
							</p>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Understanding the Gig Economy in Africa:
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								Africa's Gig Economy is a force to be reckoned
								with, reshaping the way millions of individuals
								work and earn a living. Within this evolving
								landscape, workers are increasingly embracing
								non-traditional employment arrangements, opting
								for flexibility and autonomy. Whether it's the
								creative artist, the ride-sharing driver, or the
								IT specialist, the gig workforce represents a
								diverse group of professionals. This shift
								towards gig work is driven by a myriad of
								factors, including the need for flexible
								schedules, the pursuit of economic independence,
								and the response to job market challenges. Yet,
								while the gig economy brings newfound
								opportunities, it also presents its share of
								challenges, such as income instability, limited
								social protections, and the absence of
								traditional job benefits.
							</p>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Blockchain Technology: A Transformative Force:
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								Blockchain, the groundbreaking distributed
								ledger technology, has transformed industries
								globally. It's characterized by its ability to
								securely and transparently record and verify
								transactions, all without the need for
								intermediaries. In Africa and across the world,
								blockchain's potential for disrupting the status
								quo is evident in sectors ranging from finance
								to healthcare.
							</p>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								Exploring the Intersection:
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								At the intersection of the Gig Economy and
								Blockchain lies a world of promise and
								innovation. Here are some key areas where they
								meet to shape the future of work in Africa:
							</p>
							<ul className='ml-10'>
								<li className='text-[1.2rem] font-[500]  list-decimal'>
									<span className='font-[700] mr-2'>
										Secure and Transparent Transactions:
									</span>
									Blockchain technology ensures secure and
									transparent financial transactions in the
									gig economy, reducing payment disputes and
									instilling trust between workers and
									employers.
								</li>
								<li className='text-[1.2rem] font-[500] list-decimal'>
									<span className='font-[700] mr-2'>
										Access to Financial Services:
									</span>
									Blockchain can extend access to financial
									services for gig workers, offering
									opportunities for loans, insurance, and
									more.
								</li>
								<li className='text-[1.2rem] font-[500] list-decimal'>
									<span className='font-[700] mr-2'>
										Smart Contracts:
									</span>
									Smart contracts streamline gig work by
									automating tasks such as project delivery
									and payment, reducing friction and enhancing
									efficiency.
								</li>
								<li className='text-[1.2rem] font-[500] list-decimal'>
									<span className='font-[700] mr-2'>
										Identity Verification:
									</span>{' '}
									Blockchain provides a tamper-proof system
									for identity verification, fostering a more
									reliable workforce.
								</li>
								<li className='text-[1.2rem] font-[500] list-decimal'>
									<span className='font-[700] mr-2'>
										Decentralized Marketplaces:
									</span>{' '}
									Blockchain-based platforms can empower gig
									workers and reshape the gig marketplace,
									reducing the influence of middlemen.
								</li>
							</ul>
						</div>
						<div className=''>
							<h1 className='font-[600] text-[20px] md:text-[25px] my-5'>
								The Vision for the Future
							</h1>
							<p className='text-[1.2rem] font-[500]'>
								Our conference is a platform for you to engage
								with these exciting possibilities. Join us to:
							</p>
							<ul className='ml-10'>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Share insights and success stories at the
									crossroads of the Gig Economy and
									Blockchain.
								</li>
								<li className='text-[1.2rem] font-[500] list-disc'>
									Tackle the challenges and seize the
									opportunities of gig work in Africa.
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									Explore innovative blockchain applications
									that are reshaping the future of work.
								</li>

								<li className='text-[1.2rem] font-[500] list-disc'>
									Recognize the pioneers and innovators
									driving positive change in the digital
									economy through the Africa Digital Award.
								</li>
							</ul>
						</div>
						<div className=''>
							<div className='bg-white shadow-md rounded my-6'>
								<table className='w-full table-auto'>
									<thead>
										<tr className='bg-gray-200 text-gray-600 text-sm leading-normal'>
											<th className='py-3 px-6 text-left'>
												Time
											</th>
											<th className='py-3 px-6 text-left'>
												Event
											</th>
										</tr>
									</thead>
									<tbody className='text-gray-600 text-sm font-light'>
										<tr className='border-b border-gray-200 hover:bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												8:30 AM - 9:00 AM
											</td>
											<td className='py-3 px-6 text-left'>
												Registration and Welcome Coffee
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												9:00 AM - 9:30 AM
											</td>
											<td className='py-3 px-6 text-left'>
												Opening Ceremony
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												9:30 AM - 10:30 AM
											</td>
											<td className='py-3 px-6 text-left'>
												Keynote Address: "Gig Economy
												Trends in Africa" by [Keynote
												Speaker 1]
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												10:30 AM - 11:15 AM
											</td>
											<td className='py-3 px-6 text-left'>
												Panel Discussion 1: "Gig Economy
												Transformations: Success Stories
												and Challenges"
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												11:15 AM - 11:30 AM
											</td>
											<td className='py-3 px-6 text-left'>
												Morning Networking Break
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												11:30 AM - 12:30 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Workshop 1: "Navigating the Gig
												Economy: Building Skills for
												Success"
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												12:30 PM - 1:30 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Lunch Break
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												1:30 PM - 2:30 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Panel Discussion 2: "Blockchain
												Solutions for Gig Economy
												Challenges"
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												2:30 PM - 3:15 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Workshop 2: "Smart Contracts
												Simplified: Empowering Gig
												Workers"
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												3:15 PM - 3:30 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Afternoon Networking Break
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												3:30 PM - 4:30 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Fireside Chat: "The Future of
												Work in Africa: Gig Economy and
												Blockchain Dynamics" with [Guest
												Speaker]
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												4:30 PM - 5:00 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Awards Ceremony and Closing
												Remarks
											</td>
										</tr>
										<tr className='border-b border-gray-200 hover-bg-gray-100'>
											<td className='py-3 px-6 text-left'>
												5:00 PM - 6:00 PM
											</td>
											<td className='py-3 px-6 text-left'>
												Networking Reception and
												Exhibitions
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</article>
				</div>
			</Animation>
			<Animation>
				<Schedule />
			</Animation>
			<Animation>
				<Partners second />
			</Animation>
			<Animation>
				<Footer />
			</Animation>
		</div>
	);
};

export default Adca2024;
