import React, { useState } from 'react';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'animate.css';
import { url } from '../../url';
import Animation from '../../Components/Animation';
import Modal from '../../Components/Modal';
import { Link } from 'react-router-dom';
import frame from '../../assets/imgs/award.png';

const Reservation = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [state, setState] = useState([
		{
			name: '',
			address: '',
			country: '',
			state: '',
			city: '',
			number: '',
			gender: '',
			organization: '',
			position: '',
		},
	]);
	const MySwal = withReactContent(Swal);

	const handleChange = (evt) => {
		const value =
			evt.target.type === 'checkbox'
				? evt.target.checked
				: evt.target.value;
		setState({
			...state,
			[evt.target.name]: value,
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		// =====  Uncomment this to allow nomination

		let createNominees = async () => {
			let myHeaders = new Headers();
			myHeaders.append('Content-Type', 'application/json');
			let response = await fetch(`${url}nominations`, {
				method: 'post',
				headers: myHeaders,
				body: JSON.stringify({
					name: state.name,
					address: state.address,
				}),
			});
		};
	};
	return (
		<>
			<Nav />
			<Animation>
				<div className='p-[20px] md:p-[60px] md:px-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px] uppercase'>
						reservation
					</h1>
					<div className='w-[96px] h-[5px] mt-[8px] mb-[32px] bg-primary'></div>
					<h3 className='mb-[32px] font-[600] text-[24px] leading-6'>
						Which of the ADCA events would you like to attend?
					</h3>
					<div className='md:flex justify-between items-center'>
						<div className=''>
							<a
								href='https://www.eventbrite.com/e/the-african-digital-conference-tickets-471130563197'
								target='_blank'
								rel='noopener noreferrer'
								className='w-full mx-auto md:mx-0 p-[16px]  card text-center mt-4 md:w-[646px] block mb-[16px]'
							>
								<span className='font-[600] text-[18px] md:text-[20px] text-white rounded'>
									The African Digital Conference
								</span>
							</a>
							<Link
								to='/gala-night'
								className='w-full mx-auto md:mx-0 p-[16px]  card text-center mt-4 md:w-[646px] block mb-[16px]'
								// onClick={() => setSendUserId(user._id)}
							>
								<span className='font-[600] text-[18px] md:text-[20px] text-white rounded'>
									The Africa Digital Awards Gala Night
								</span>
							</Link>
							<Link
								to='/both-event'
								className='w-full mx-auto md:mx-0  p-[16px]  card text-center mt-4 md:w-[646px] block mb-[16px]'
								// onClick={() => setSendUserId(user._id)}
							>
								<span className='font-[600] text-[18px] md:text-[20px] text-white rounded'>
									Both Events
								</span>
							</Link>
						</div>
						<span className=''>
							<img src={frame} alt='about' />
						</span>
					</div>
				</div>
			</Animation>
			<Footer />
		</>
	);
};

export default Reservation;
