import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import speaker2 from '../../assets/imgs/award/new/speaker2.jpg';
import speaker1 from '../../assets/imgs/award/new/speaker1.jpg';
import speaker3 from '../../assets/imgs/award/new/speaker3.jpg';
import cong3 from '../../assets/imgs/award/new/cong3.jpg';
import cong2 from '../../assets/imgs/award/new/cong2.jpg';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
const images = [speaker1, speaker2, speaker3, cong3, cong2];
const CustomCarousel = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [isMouseOver, setIsMouseOver] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!isMouseOver) {
				setCurrentSlide((prevSlide) =>
					prevSlide === images.length - 1 ? 0 : prevSlide + 1
				);
			}
		}, 5000); // Change image every 5 seconds

		return () => clearInterval(interval);
	}, [images, isMouseOver]);

	const goToPreviousSlide = () => {
		setCurrentSlide((prevSlide) =>
			prevSlide === 0 ? images.length - 1 : prevSlide - 1
		);
	};

	const goToNextSlide = () => {
		setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
	};

	return (
		<div
			className='relative h-[90vh] overflow-hidden'
			onMouseEnter={() => setIsMouseOver(true)}
			onMouseLeave={() => setIsMouseOver(false)}
		>
			<div className='absolute inset-0 flex'>
				{images.map((image, index) => (
					<div
						key={index}
						className={`w-full h-full absolute transform transition-transform ${
							index === currentSlide
								? 'translate-x-0 opacity-100'
								: index < currentSlide
								? 'translate-x-full opacity-0'
								: 'translate-x-full opacity-0'
						}`}
					>
						<img
							src={image}
							alt={`Slide ${index + 1}`}
							className='w-full h-full object-cover object-top'
						/>
						<div className='absolute inset-0 flex items-center justify-center text-white text-center bg-opacity-70 bg-black'>
							<div>
								<h3 className='font-[600] text-[27px] md:text-[30px] text-white leading-[56px] mb-[40px] md:w-[70%] text-center mx-auto'>
									AFRICAN DIGITAL CONFERENCE AND AWARDS (ADCA)
								</h3>
								<Link to='/reservation'>
									<button className='md:min-w-[400px] md:h-[56px] py-[12px] px-[13px] md:px-[16px] bg-primary font-[600] text-[16px] md:text-[22px] rounded-full'>
										RESERVE A SEAT
									</button>
								</Link>
							</div>
						</div>
					</div>
				))}
			</div>

			<button
				className='absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-3xl'
				onClick={goToPreviousSlide}
			>
				<AiOutlineLeft />
			</button>

			<button
				className='absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-3xl'
				onClick={goToNextSlide}
			>
				<AiOutlineRight />
			</button>
		</div>
	);
};

export default CustomCarousel;
