import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-modal';
// import LazyLoad from 'react-lazy-load-image-component';
Modal.setAppElement('#root');
const CustomModal = ({ selectedImage, closeModal }) => {
	const modalStyle = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width: '60%',
			height: '80%',
		},
	};
	return (
		// <div className='w-[80%] h-[70%]'>
		<Modal
			isOpen={selectedImage !== null}
			onRequestClose={closeModal}
			contentLabel='Selected Image'
			style={modalStyle}
		>
			{/* <button className='close-button' onClick={closeModal}>
				Close
			</button> */}
			{/* <LazyLoad height={400}> */}
			{/* <LazyLoadImage
					src={selectedImage}
					alt='Image Description'
					effect='blur'
					// placeholderSrc={one}
					// onClick={() => openModal(image)}
				/> */}
			<img src={selectedImage} alt='' className='w-full h-full' />
		</Modal>
		// </div>
	);
};

export default CustomModal;
