import React from 'react';
import About from '../../Components/About';
import Footer from '../../Components/Footer';
import Nav from '../../Components/Nav';
import frame from '../../assets/imgs/award.png';
import Animation from '../../Components/Animation';

const Rules = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Nav />
			<Animation>
				<div className='pt-[64px] pb-[32px] mx-[20px] md:mx-[80px]'>
					<h1 className='md:max-w-[1164px] font-[600] text-[20px] md:text-[30px]'>
						The Rules
					</h1>
					<div className='w-[64px] h-[5px] mt-[8px] bg-primary'></div>
					<div className='block md:flex'>
						<article className='w-5/6 mx-auto md:mx-0 md:w-[817px] md:mr-[66px] mt-[32px] mb-[30px] md:mb-0'>
							<ul>
								<li className='font-[500] text-[16px] md:text-[24px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									Only nominations received within the
									stipulated dates will move to the voting
									stage.
								</li>
								<li className='font-[500] text-[16px] md:text-[24px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									Nominees whose entries violate or do not
									meet any of our categories will be
									disqualified.
								</li>
								<li className='font-[500] text-[16px] md:text-[24px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									All entries submitted to ADCA cannot be
									retrieved or deleted. ADCA will not be held
									liable for any loss or damages on
									information received.
								</li>
								<li className='font-[500] text-[16px] md:text-[24px] leading-8 mb-[16px]'>
									<span className='mr-2'>&#9654;</span>
									An individual can only vote for a nominee
									once per day.
								</li>
							</ul>
						</article>
						<span className=''>
							<img src={frame} alt='about' />
						</span>
					</div>
				</div>
			</Animation>
			<Footer />
		</div>
	);
};

export default Rules;
